@import "../../../../Constant.scss";

.dashboard-section{
    color: $color8;
    padding: 30px;
    border-radius: 30px;
    display: grid;
    grid-gap: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.274);
    .text-dashboard{
        p{
            font-size: 18px;
            font-weight: 500;
        }
    }
    .dates-dashboard{
        display: flex;
        grid-gap: 10px;
        justify-content: space-evenly;
        .info-dashboard{
            display: grid;
            grid-gap: 5px;
            .datep{
                display: flex;
                grid-gap: 10px;
                align-content: center;
                align-items: center;
                font-size: 12px;
            }
            svg{
                color: $color8;
                font-size:16px;
                padding: 10px;
                border-radius: 50%;
                @extend %glass1;
                path{
                    stroke: $color8 !important;
                }
            }
        }
    }
}

.css-5b1ays-MuiPieArc-root {
    stroke: transparent !important;
    stroke-width: 0 !important;
}

.css-1fdol2n-MuiPieArc-root {
    stroke: transparent !important;
    stroke-width: 0 !important;
}

.sex{
    background-image: linear-gradient(to right top, #964fe8, #8f49e1, #8843d9, #823cd2, #7b36cb);
}

.salidas{
    background-image: linear-gradient(to right top, #1e67e2, #1f6cdf, #2471db, #2c75d7, #3479d3);
}


@media screen and (max-width: 500px){
    .dashboard-section{
        padding: 20px;
        .dates-dashboard{
            flex-direction: column;
        }
        .text-dashboard{
            p{
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}
