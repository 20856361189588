@import "../../../../Constant.scss";

.item-ticket{
    display: grid;
    grid-template-columns: 75px auto 50px;
    border: none;
    padding: 15px;
    margin: 0 10px 0 0;
    background: $color11;
    border-radius: 40px;
    align-content: center;
    align-items: center;
    font-family: $font;
    text-decoration: none;
    .name{
        font-weight: 600;
        font-size: 16px;
        color: $color6;
        @extend %trans;
    }
    p{
        font-size: 12px;
        color: $color7;
        @extend %trans;
    }
    svg{
        font-size: 20px;
        border-radius: 50%;
        @extend %trans;
        cursor: pointer;
    }
    .search{
        color: $color11;
        background: $color1;
        font-size: 30px;
        padding: 10px;
        border-radius: 50%;
        @extend %trans;
        cursor: pointer;
    }
    .search-rest{
        color: $color11;
        background: $color7;
        font-size: 30px;
        padding: 10px;
        border-radius: 50%;
        @extend %trans;
        cursor: pointer;
    }
    &:hover{
        background: $color1;
        .name{
        color: $color11;
        @extend %trans;
        }
        p{
            color: $color11;
            @extend %trans;
        }
        .search{
            color: $color1;
            background: $color11;
            font-size: 25px;
            @extend %trans;
        }
        .search-rest{
            color: $color7;
            background: $color11;
            font-size: 25px;
            @extend %trans;
        }
    }
    .status{
        svg{
            font-size: 16px;
            padding: 10px;
        }
    }
    .send{
        svg{
            background: $color4;
            color: $color11;
        }
    }
    .answer{
        svg{
            background: $color12;
            color: $color11;
        }
    }
    .close{
        svg{
            background: #66e21e;
            color: $color11;
        }
    }
    .error{
        svg{
            background: $color10;
            color: $color11;
        }
    }
}

.title-status-ticket{
    font-size: 10px;
    padding: 10px;
}

.input-date{
    display: flex;
    width: 90%;
    textarea{
        font-family: Poppins;
        font-size: 14px;
        padding: 10px 15px;
        border-radius: 16px;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        overflow-y: auto;
        max-height: 10vh;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
}

.container-input-img{
    max-height: 25vh;
    overflow-y: auto;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(169, 169, 169, 0.562);
        border-radius: 10px;
    }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
    &:focus, &:active {
        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
    }
}
.cotainer-button-mens{
    display: flex;
    justify-content: space-between;
}

.windons-messenger{
    padding: 2vh 2vh 0% 2vh;
    display: grid;
    grid-template-rows: 10vh 75vh;
    .windons-title-messenger{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        .container-button-messenger{
            display: flex;
            grid-gap: 2vh;
            align-items: flex-start;
            justify-content: flex-end;
        }
    }
    .windons-section-info-messenger{
        grid-gap: 2%;
        display: grid;
        grid-template-columns: repeat(2,49%);
        .section-info-messenger{
            display: flex;
            flex-direction: column;
            height: 100%;
            .messenger{
                display: grid;
                justify-items: center;
                max-width: 100%;
                .container-des{
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    @extend %glass;
                    padding: 20px;
                    border-radius: 20px;
                    margin-bottom: 20px;
                }
                .container-messenger{
                    display: flex;
                    flex-direction: column;
                    width: 95%;
                    @extend %glass;
                    padding: 2vh;
                    grid-gap: 10px;
                    border-radius: 20px;
                    overflow-y: auto;
                    height: 70vh;
                    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(169, 169, 169, 0.562);
                        border-radius: 10px;
                    }
                    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                    &::-webkit-scrollbar-track {
                        @extend %glass;
                        border-radius: 10px;
                    }
                    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                    &::-webkit-scrollbar {
                        width: 8px;
                    }
                    .container-messenger-send{
                        display: grid;
                        justify-content: start;
                        .mensseger-send{
                            display: grid;
                            color: $color11;
                            background: $color2;
                            border-radius: 16px;
                            padding: 10px;
                            justify-items: end;
                            .messenger-img{
                                display: grid;
                                grid-gap: 20px;
                                margin-bottom: 15px;
                                img{
                                    width: 60px;
                                    height: auto;
                                    border-radius: 16px;
                                }
                            }
                            .messenger{
                                font-size: 14px;
                            }
                            .date-messenger{
                                font-size: 9px;
                                color: $color9;
                            }
                        }
                    }
                    .container-messenger-hot{
                        display: grid;
                        justify-content: end;
                        .mensseger-hot{
                            display: grid;
                            color: $color11;
                            background: $color1;
                            border-radius: 16px;
                            padding: 10px;
                            justify-items: start;
                            .messenger-img{
                                display: grid;
                                grid-gap: 20px;
                                margin-bottom: 15px;
                                img{
                                    width: 60px;
                                    height: auto;
                                    border-radius: 16px;
                                }
                            }
                            .messenger{
                                font-size: 14px;
                            }
                            .date-messenger{
                                font-size: 9px;
                                color: $color9;
                            }
                        }
                    }
                }
            }
        }
    }
    .section-info-messenger1{
        grid-gap: 2%;
        display: grid;
        .form-info-messenger{
            display: flex;
            flex-direction: column;
            .form-scroll-messenger{
                height: 100%;
                display: flex;
                flex-direction: column;
                grid-gap: 10px;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .input-date-messenger{
                    display: grid;
                    width: 100% !important;
                    label{
                        padding: 0 15px;
                        font-size: 12px;
                    }
                    textarea{
                        font-family: Poppins;
                        font-size: 14px;
                        height: 20vh;
                        padding: 10px 15px;
                        border-radius: 16px;
                        @extend %glass1;
                        resize: vertical; 
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                }
                .container-input-img-messenger{
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    grid-template-areas: auto;
                    grid-gap: 10px;
                    max-height: 30vh;
                    overflow-y: scroll;
                    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(255, 255, 255, 0.562);
                        border-radius: 0px;
                    }
                    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                    &::-webkit-scrollbar-track {
                        @extend %glass;
                        border-radius: 0px;
                    }
                    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                    &::-webkit-scrollbar {
                        width: 0px;
                    }
                    .input-img{
                        position: relative;
                        overflow: hidden;  /* Asegura que el contenido que exceda los bordes del contenedor sea cortado */
                        border-radius: 16px;
                        min-height: 100px;
                        img{
                            width: 100% !important;
                            display: block;
                            object-fit: cover; /* Ajusta la imagen para cubrir el área designada, respetando el aspecto ratio */
                            height: 100%; 
                        }
                        .delete-img{
                            position: absolute;
                            top: 2px; 
                            left: 1px;
                            background: none;
                            border: none;
                            z-index: 50;
                            svg{
                                cursor: pointer;
                                font-size: 18px;
                                padding: 3px;
                                border-radius: 50%;
                                color: $color11;
                                background: $color10;
                            }
                        }
                    }
                }
                .input-load-messenger{
                    display: flex;
                    .custom-file-upload{
                        color: $color11;
                        background: #1E67E2;
                        border: #1E67E2 solid 2px;
                        padding: 8px 10px;
                        text-decoration: none;
                        border-radius: 14px;
                        display: flex;
                        align-content: center;
                        align-items: center;
                        grid-gap: 5px;
                        font-size: 10px;
                        @extend %trans;
                        justify-content: center;
                        font-family: $font;
                        cursor: pointer;
                        svg{
                            font-size: 16px;
                        }
                        &:hover{
                            color: #1E67E2;
                            background: none;
                            @extend %trans;
                            svg{
                                color: #1E67E2;
                            }
                        }
                    }
                    input[type="file"] {
                        display: none;
                    }

                }
                .cotainer-button-messenger{
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
}

@media screen and (max-width: 950px){
    .windons-messenger{
        .windons-title-messenger{
            display: grid;
            grid-template-columns: 1fr;
            .container-button-messenger{
                display: flex;
                grid-gap: 5px;
                align-items: flex-start;
                justify-content: flex-end;
                p{
                    display: none;
                }
            }
        }
        .windons-section-info-messenger{
            grid-template-columns: 100%;
            max-height: 75vh;
            overflow-y: auto;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.822);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(14.2px);
                -webkit-backdrop-filter: blur(14.2px);
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
            .section-info-messenger .messenger .container-messenger {
                width: 90%;
                padding: 2%;
                margin: 0 2%;
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 0px;
                }
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .section-info-messenger1 {
            margin: 0 2%;
        }
    }
}

@media screen and (max-width: 450px){
    .subtitle4{
        font-size: 11px;
        line-height: 10px;
    }
    .windons-messenger .windons-section-info-messenger .section-info-messenger .messenger .container-messenger .container-messenger-send .mensseger-send .messenger {
        font-size: 12px;
    }
    .windons-messenger .windons-section-info-messenger .section-info-messenger .messenger .container-messenger .container-messenger-hot .mensseger-hot .messenger {
        font-size: 12px;
    }
    .windons-messenger .windons-section-info-messenger .section-info-messenger .messenger .container-messenger .container-messenger-hot .mensseger-hot .messenger-img {
        grid-gap: 5px;
    }
    .windons-messenger .windons-section-info-messenger .section-info-messenger .messenger .container-messenger .container-messenger-hot .mensseger-hot .messenger-img img {
        width: 40px;
        height: auto;
        border-radius: 10px;
    }
}