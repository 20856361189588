@import "../../../Constant.scss";

.main-inte{
    display: grid;
    min-height: 100vh;
    grid-gap: 5vw;
    background: #f9f9f9ff;
}

.button-x-flotant{
    position:fixed;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    right:20px;
    cursor: pointer;
    @extend %trans;
    margin-bottom: -50px;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}



.container-gallery-catalog{
    display: grid;
    width: 90%;
    padding: 3vw; 
    color:$color6;
    text-align: center;
    grid-gap: 30px;
    background: $color8;
    border-radius: 40px;
    margin: 20px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
    .container-gallery-catalog-title{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        grid-gap: 10px;
        .gallery-title-catalog {
            font-size: 30px;
            color: $color1;
            font-weight: 500;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            margin-top: 20px;
            span{
                font-weight: 650;
                font-style: oblique;
            }
        }
        .gallery-icon-catalog{
            margin-top: 15px;
            height: 40px;
            width: 40px;
        }
    }
    .gallery-two-column{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 15px;
        max-height: 60vh;
        align-items: center;
        justify-items: center;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.13);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 3px;
        }
        .gallery-two-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-two-column-produc-img{
                width: 150px;
                height: auto;
                border-radius: 10px;
            }
        }
        .gallery-one-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-one-column-produc-img{
                width: 200px;
                height: auto;
                border-radius: 20px;
            }
        }
        .contect-two-column-produc{
            display: flex;
            margin: 5px 0px;
            align-items: center;
            grid-gap: 10px;
            h4{
                font-size: 12px;
            }
            p{
                font-size: 12px;
            }
        }
        .description-two-column-produc{
            width: 190px;
            p{
                text-align: justify;
                font-size: 11px;
            }
        }
    }
    .gallery-tree-column{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        align-items: center;
        justify-items: center;
        div:nth-child(3) {
            grid-column: span 2; /* Hace que el tercer ítem ocupe dos columnas */
        }
        .gallery-two-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-two-column-produc-img{
                width: auto;
                height: 150px;
                border-radius: 10px;
            }
        }
        .gallery-one-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-one-column-produc-img{
                width: 200px;
                height: auto;
                border-radius: 20px;
            }
        }
        .contect-two-column-produc{
            display: flex;
            margin: 5px 0px;
            align-items: center;
            grid-gap: 10px;
            h4{
                font-size: 12px;
            }
            p{
                font-size: 12px;
            }
        }
        .description-two-column-produc{
            width: 190px;
            p{
                text-align: justify;
                font-size: 11px;
            }
        }
    }
    .gallery-four-column-scroll{
        max-height: 70vh;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.116);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 3px;
        }
    }
    .space-title{
        margin: 15px 0;
    }
    .space-title1{
        margin-bottom: 15px;
    }
    .container-gallery-catalog-description{
        display: flex;
        justify-content: center;
    }
    .space-bottom{
        margin-top: 40px;
    }
    .gallery-description-catalog{
        margin-bottom: 25px;
        max-width: 550px;
    }
    .gallery-four-column{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 20px;
        align-items: center;
        justify-items: center;
        .gallery-four-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-four-column-produc-img{
                width: 150px;
                height: auto;
                border-radius: 10px;
            }
        }
        .contect-two-column-produc{
            display: flex;
            margin: 5px 0px;
            align-items: center;
            grid-gap: 10px;
            justify-content: flex-start;
            h4{
                font-size: 12px;
            }
            p{
                font-size: 12px;
            }
        }
        .description-four-column-produc{
            width: 150px;
            p{
                text-align: justify;
                font-size: 11px;
            }
        }
    }
    .gallery-four-column1{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        align-items: center;
        justify-items: center;
        .gallery-four-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-four-column-produc-img{
                width: 150px;
                height: auto;
                border-radius: 10px;
            }
        }
        .contect-two-column-produc{
            display: flex;
            margin: 5px 0px;
            align-items: center;
            grid-gap: 10px;
            justify-content: flex-start;
            h4{
                font-size: 12px;
            }
            p{
                font-size: 12px;
            }
        }
        .description-four-column-produc{
            width: 150px;
            p{
                text-align: justify;
                font-size: 11px;
            }
        }
    }
    .gallery-five-column{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        max-height: 60vh;
        align-items: center;
        justify-items: center;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.192);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 3px;
        }
        div:nth-child(3) {
            grid-column: span 2; /* Hace que el tercer ítem ocupe dos columnas */
        }
        .gallery-two-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-two-column-produc-img{
                width: 200px;
                height: auto;
                border-radius: 10px;
            }
        }
        .gallery-one-column-produc{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            .gallery-one-column-produc-img{
                width: 220px;
                height: auto;
                border-radius: 20px;
            }
        }
        .contect-two-column-produc{
            display: flex;
            margin: 5px 0px;
            align-items: center;
            grid-gap: 10px;
            h4{
                font-size: 12px;
            }
            p{
                font-size: 12px;
            }
        }
        .description-two-column-produc{
            width: 190px;
            p{
                text-align: justify;
                font-size: 11px;
            }
        }
    }
}

@media (max-width: 950px) {
    .container-gallery-catalog{
        .gallery-two-column {
            grid-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            .gallery-one-column-produc .gallery-one-column-produc-img {
                width: 150px;
                height: auto;
                border-radius: 20px;
            }
            .description-two-column-produc {
                width: 140px;
            }
        }
        .gallery-five-column {
            grid-gap: 10px;
            .gallery-one-column-produc .gallery-one-column-produc-img {
                width: 150px;
                height: auto;
                border-radius: 20px;
            }
            .description-two-column-produc {
                width: 140px;
            }
            .gallery-one-column-produc {
                justify-content: center;
            }
        }
        .gallery-four-column {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
        }
        .gallery-tree-column {
            grid-template-columns: 1fr;
            grid-gap: 10px;
            div:nth-child(3) {
                grid-column: span 1;
            }
        }
    }
}

@media (max-width: 750px) {
    .container-gallery-catalog{
        .gallery-four-column {
            grid-template-columns: repeat(2, 1fr);
        }
        .gallery-four-column1 {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
        }
    }
}


@media (max-width: 650px) {
    .container-gallery-catalog{
        grid-gap: 10px;
        border-radius: 20px;
        .container-gallery-catalog-title{
            grid-gap: 5px;
            .gallery-title-catalog {
                font-size: 20px;
                margin-top: 10px;
                width: 150px;
                line-height: 20px;
        
            }
        }
        .container-gallery-catalog-title .gallery-icon-catalog {
            margin-top: 5px;
            height: 20px;
            width: 20px;
        }
        .gallery-five-column {
            display: flex;
            flex-direction: column;
        }
        div:nth-child(3) {
            grid-column: span 1; /* Hace que el tercer ítem ocupe dos columnas */
        }
        .gallery-four-column {
            grid-template-columns: repeat(1, 1fr);
        }
        .gallery-four-column1 {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
        }
        .gallery-tree-column .gallery-two-column-produc .gallery-two-column-produc-img {
            width: auto;
            height: 100px;
            border-radius: 10px;
        }
        .gallery-tree-column .gallery-two-column-produc {
            justify-content: center;
        }
    }
}

.container-button-quotemform{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

@media (max-width: 390px) {
    .container-gallery-catalog{
        .gallery-two-column {
            grid-gap: 10px;
            grid-template-columns: 1fr;
        }
    }
}