@import "../../../../Constant.scss";


.form-blogs{
    width: 100%;
    .creator-form-blogs{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 60vh 80px;
        grid-template-areas: 
        "form-blogs-section  form-blogs-section1"
        "cotainer-button-mens cotainer-button-mens"
        ;
        .form-blogs-section{
            grid-area: form-blogs-section;
            justify-items: center;
            height: 60vh;
            display: grid;
            padding: 0 0 20px 0;
            overflow-y: auto;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
        }
        .form-blogs-section1{
            grid-area: form-blogs-section1;
            height: 60vh;
            padding: 0 10px 0 0 ;
            overflow-y: auto;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
            .button-load-section{
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;
            }
        }
        .input-message{
            display: grid;
            width: 90%;
            padding: 0 5% 0 0;
            margin-bottom: 20px;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color7;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                min-height: 35vh;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-date{
            display: grid;
            width: 90%;
            padding: 0 5% 0 0;
            label{
                padding: 0 15px;
                font-size: 11px;
                color:$color7;
            }
            input{
                width: 95%;
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            select{
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .cotainer-button-mens{
            display: grid;
            justify-content: end;
            align-content: center;
            align-items: center;
            grid-area: cotainer-button-mens;
        }
    }
}