@import "../../../../../Constant.scss";

.product-admin-produc-bass{
    padding: 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    height: 90%;
    min-height: 74vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .form-contact{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 280px auto;
        grid-gap: 5%;
        .column-img{
            display: grid;
            grid-gap: 10px;
            .custom-file-upload{
                color: $color11;
                background: #1E67E2;
                border: #1E67E2 solid 2px;
                padding: 10px 15px;
                text-decoration: none;
                border-radius: 16px;
                display: flex;
                align-content: center;
                align-items: center;
                grid-gap: 10px;
                font-size: 14px;
                @extend %trans;
                justify-content: center;
                font-family: $font;
                margin-bottom: 30px;
                cursor: pointer;
                svg{
                    font-size: 22px;
                }
                &:hover{
                    color: #1E67E2;
                    background: none;
                    @extend %trans;
                    svg{
                        font-size: 24px;
                    }
                }
            }
        }
        .column-form{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                option{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-date:nth-child(1) {
                grid-column: span 2;
            }
            .input-date:nth-child(2) {
                grid-column: span 2;
            }
            .input-date:nth-child(3) {
                grid-column: span 2;
            }
            .input-date:nth-child(4) {
                grid-column: span 1;
            }
            .input-date:nth-child(5) {
                grid-column: span 1;
            }
            .input-date:nth-child(6) {
                grid-column: span 1;
            }
            .input-date:nth-child(7) {
                grid-column: span 1;
            }
            .input-date:nth-child(8) {
                grid-column: span 1;
            }
            .input-date:nth-child(9) {
                grid-column: span 1;
            }
            .input-date:nth-child(10) {
                grid-column: span 1;
            }
            .input-date:nth-child(11) {
                grid-column: span 1;
            }
            .input-date:nth-child(12) {
                grid-column: span 2;
            }
            .input-date:nth-child(13) {
                grid-column: span 2;
            }
            .input-date:nth-child(14) {
                grid-column: span 2;
            }
            .input-date:nth-child(15) {
                grid-column: span 4;
            }
            .input-date:nth-child(16) {
                grid-column: span 4;
            }
        }
        .section-img{
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            align-content: center;
            grid-gap: 30px;
            margin-bottom: 60px;
            .input-img{
                width: 250px;
                height: 250px;
                border-radius: 50%; /* Esto crea un círculo */
                overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                position: relative; /* Permite centrar vertical y horizontalmente */
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                    object-position: center center; /* Centra la imagen */
                }
            }
        }
        .input-message{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color8;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-button{
            display: flex;
            margin-top: 15px;
        }
    }
}

.foil-product{
    padding: 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    height: 90%;
    min-height: 75vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .form-product{
        width: 100%;
        .creator-form-product{
            display: grid;
            grid-gap: 30px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 53vh 80px;
            grid-template-areas: 
            "form-blogs-section  form-blogs-section1"
            "cotainer-button-mens cotainer-button-mens"
            ;
            .form-blogs-section{
                grid-area: form-blogs-section;
                justify-items: center;
                height: 55vh;
                display: grid;
                padding: 0 0 20px 0;
                overflow-y: auto;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
            }
            .form-blogs-section1{
                grid-area: form-blogs-section1;
                height: 60vh;
                padding: 0 10px 0 0 ;
                overflow-y: auto;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .button-load-section{
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    align-items: center;
                }
            }
            .input-message{
                display: grid;
                width: 90%;
                padding: 0 5% 0 0;
                margin-bottom: 20px;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                    color: $color7;
                }
                textarea{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    min-height: 25vh;
                    resize: vertical; 
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-date{
                display: grid;
                width: 90%;
                padding: 0 5% 0 0;
                label{
                    padding: 0 15px;
                    font-size: 11px;
                    color:$color7;
                }
                input{
                    width: 95%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 10px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 10px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .cotainer-button-mens{
                display: flex;
                align-content: center;
                align-items: center;
                grid-area: cotainer-button-mens;
            }
        }
    }
}
