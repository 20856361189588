@import "../../../../Constant.scss";

.sention-search{
    width: 100%;
    display: flex;
    grid-gap: 10px;
    justify-content: flex-start; // Aquí está el cambio
    align-items: center;
    align-content: center;
    form{
        display: flex;
    }
    .input-search{
        font-size: 16px;
        color: $color6;
        padding:20px;
        border-radius: 25px;
        border: none;
        @extend %glass;
        font-family: $font;
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .button-search{
        border: none; 
        background: none;
        cursor: pointer;
        display: grid;
        align-items: center;
        justify-items: center;
        justify-content: center;
        align-content: center;
        svg{
            font-size: 25px;
            padding: 10px;
            background: $color9;
            color: $color8;
            border-radius: 50%;
            @extend %trans;
            &:hover{
                font-size: 28px;
                padding: 10px;
                background: $color7;
                color: $color8;
                @extend %trans;
            }
        }
    }
    .button-menu{
        display: none;
        border: none; 
        background: none;
        cursor: pointer;
        align-items: center;
        justify-items: center;
        justify-content: center;
        align-content: center;
        z-index: 999;
        svg{
            font-size: 30px;
            padding: 10px;
            background: $color1;
            color: $color8;
            border-radius: 50%;
            @extend %trans;
            &:hover{
                font-size: 35px;
                padding: 10px;
                background: $color7;
                color: $color8;
                @extend %trans;
            }
        }
    }
}

@media screen and (max-width: 650px){
    .sention-search{
        grid-gap: 0px;
        .input-search{
            display: none;
        }
    }
}

@media screen and (max-width: 550px){
    .sention-search{
        .button-menu{
            display: grid;
        }
    }
}