@import "../../../../Constant.scss";

.list-users{
    display: grid;
    align-content: space-between;
    height: 100%;
    .title-equip-list{
        display: grid;
        grid-template-columns: 45px 25% auto 20% 100px 200px;
    }
    .contect-list-users{
        display: grid;
        grid-gap: 1vh;
        grid-template-rows: 5vh 5vh 65vh 5vh;
        .list{
            display: flex;
            flex-direction: column;
            height: 60vh;
            grid-gap: 5px;
            padding: 2vh;
            overflow-y: auto; 
            overflow-x: hidden;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
        }
        .encabezado-list{
            display: flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    .pagination-numb{
        display: grid;
        justify-items: center;
    }
}

@media screen and (max-width: 1250px){
    .list-users{
        .contect-list-users{
            grid-gap: 1vh;
            grid-template-rows: 5vh 70vh 5vh;
            .list{
                display: flex;
                flex-direction: column;
                height: 65vh;
                padding: 2vh;
            }
        }
    }
}

@media screen and (max-width: 1150px){
    .button-new2{
        display: none !important;
    }
}

@media screen and (max-width: 680px){
    .list-users{
        .title-equip-list{
            display: none;
        }
    }
}