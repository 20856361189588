@import "../../../Constant.scss";

.gallery-register{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    padding: 5% 5% 0 5%;
    grid-gap: 40px;
    margin-bottom: 100px;
    .gallery-register-item{
        display: grid;
        align-content: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
    }
    img{
        border-radius: 30px;
    }
}

.page-gallery{
    display: grid;
    min-height: 90vh;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}

.main-gallery{
    width: 100%;
    .barra-gallery{
        display: flex;
        position: fixed;
        justify-content: space-between;
        align-items: center;
        padding: 20px 5vw;
        background: $color5;
        bottom: 0;
        width: 90vw;
        color: $color8;
        .button-email{
            display: flex;
            width: 150px;
            background: $color8;
            border: 2px solid $color8;
            border-radius: 16px;
            padding: 10px 10px;
            align-items: center;
            font-family: Poppins;
            font-weight: 600;
            color: $color5;
            font-size: 16px;
            cursor: pointer;
            text-decoration: none;
            justify-content: center;
            &:hover{
                border: 2px solid $color8;
                color: $color8;
                background: none;
                @extend %trans;
            }
        }
    }
}


@media screen and (max-width: 1100px){
    .gallery-register{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 850px){
    .gallery-register{
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        img{
            border-radius: 20px;
        }
    }
}