@import "../../../../Constant.scss";


.info-section-5{
    display: grid;
    width: 100%;
    margin: 5vh 0px 10vh 0px;
    justify-items: center;
    justify-content: center;
    .info-section-5-container{
        display: grid;
        width: 95%;
        margin-bottom: 100px;
        grid-gap: 5%;
        max-width: 1400px;
        grid-template-columns: 75% 20%;
        .info-left-section-5{
            display: grid;
            padding: 50px;
            grid-template-columns: repeat(2,1fr);
            background-image: linear-gradient(to right top, #ececec, #f1f1f1, #f5f5f5, #fafafa, #ffffff);
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.205);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 40px;
            .left-section-5-contect{
                display: grid;
                align-content: space-between;
                .info-left-section-5-contect{
                    display: grid;
                    grid-gap: 40px;
                }
            }
            .left-section-5-contect-img{
                display: grid;
                justify-items: center;
                align-content: center;
                img{
                    max-width: 400px;
                }
            }
        }
    }
    .info-right-section-5{
        display: grid;
        align-content: space-between;
        text-align: center;
        grid-gap: 30px;
        .info-left-section-5-card{
            border-radius: 40px;
            color: $color11;
            padding: 20px 10px;
            h2{
                font-size: 50px;
                font-weight: 300;
                text-align: center;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
            }
            p{
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
                span{
                    font-style: oblique;
                    font-weight: 600;
                }
            }
        }
        .bg-0 {
            background-color: $color1;
        }
        .bg-1 {
            background-color: $color2;
        }
        .bg-2 {
            background-color: $color3; 
        }
        .bg-3 {
            background-color: $color1;
        }
    }
}

@media screen and (max-width: 850px){
    .info-section-5 .info-section-5-container {
        grid-template-columns: 1fr;
    }
    .info-section-5 .info-right-section-5 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 750px){
    .info-section-5 .info-section-5-container {
        grid-gap: 0px;
    }
    .info-section-5 .info-right-section-5 {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .info-section-5 .info-section-5-container .info-left-section-5 {
        padding: 30px;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 40px;
    }
}

@media screen and (max-width: 750px){
    .info-section-5 .info-right-section-5 {
        margin-top: 30px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
    .info-section-5 .info-section-5-container .info-left-section-5 {
        padding: 30px;
        grid-template-columns: 1fr;
        border-radius: 40px;
    }
    .info-section-5 .info-section-5-container .info-left-section-5 .left-section-5-contect-img img {
        margin-top: 30px;
        max-width: 40%;
    }
    .info-section-5 .info-section-5-container .info-left-section-5 .left-section-5-contect .info-left-section-5-contect {
        display: grid;
        grid-gap: 20px;
        margin: 20px 0;
    }
    .info-section-5 .info-right-section-5 .info-left-section-5-card h2 {
        font-size: 30px;
    }
    .info-section-5 .info-right-section-5 .info-left-section-5-card p {
        font-size: 12px;
        line-height: 13px;

    }
}