@import "../../../../Constant.scss";

.windons-two{
    padding: 2vh 2vh 0% 2vh;
    display: grid;
    grid-template-rows: 8vh 68vh 8vh;
    .windons-title-section{
        display: grid;
        min-height: 150px;
        grid-template-columns: auto auto 150px;
        align-items: start;
        p{
            font-size: 12px;
            margin-left: 10px;
            color: $color7;
            margin-bottom: 5px;
        }
        .windons-title-info{
            display: flex;
            .input-date{
                display: grid;
                width: 90%;
                padding: 0 5% 0 0;
                label{
                    padding: 0 12px;
                    font-size: 10px;
                    color:$color7;
                }
                input{
                    width: 95%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 15px;
                    border-radius: 16px;
                    border: none;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 8px 10px;
                    border-radius: 12px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
        }
    }
    .windons-section-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 10px;
        width: 100%;
        grid-template-areas: "seleccione-group  seleccione-employe";
        .section-info-two{
            max-height: 67vh;
            p{
                font-size: 12px;
            }
            .list-info{
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                height: 86%;
                margin-bottom: 20px;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-produc{
                    display: grid;
                    grid-template-columns: 7vh auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    cursor: pointer;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    margin-bottom: 10px;
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            width: 7vh;
                            height: 7vh;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 7vh;
                            height: 7vh;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
                .item-kits-as{
                    display: grid;
                    grid-template-columns: 75px auto 100px 40px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 30px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .contect-seller-item-kits{
                        display: grid;
                        text-align: center;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 7vh auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    margin-bottom: 10px;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            width: 7vh;
                            height: 7vh;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 7vh;
                            height: 7vh;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
        .section-info1 {
            @extend %glass;
            padding: 20px;
            border-radius: 40px;
            height: 100%;
            h2 {
                margin: 20px 15px;
            }
            .input-date {
                display: grid;
                width: 100%;
                label {
                    padding: 0 12px;
                    font-size: 10px;
                    color: $color7;
                }
                input,
                select,
                textarea,
                option { // Añadido option aquí también
                    width: 100%; // Asegurar que todos tomen el 100% del ancho
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 15px;
                    border-radius: 14px;
                    @extend %glass1;
                    border: none;
                    box-sizing: border-box; 
                    &:focus, &:active {
                        outline: none;
                    }
                }
                textarea {
                    resize: vertical;
                    box-sizing: border-box;
                    min-height: 15vh;
                    margin-bottom: 10px;
                }
            }
        }
        .seleccione-group{
            grid-area: seleccione-group;
        }
        .seleccione-employe{
            grid-area: seleccione-employe;
        }
    }
    .windons-section-group{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 30px;
        width: 100%;
        .section-info-group{
            max-height: 100%;
            margin-bottom: 5%;
            .list-info-group{
                margin-top: 1vh;
                display: flex;
                flex-direction: column;
                max-height: 70vh;
                overflow-y: auto;
                grid-gap: 10px;
                p{
                    font-size: 12px;
                }
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-kits-group{
                    text-decoration: none;
                    display: grid;
                    grid-template-columns: 75px auto 150px 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .contect-seller-item-kits{
                        text-align: center;
                        display: grid;
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 30px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
    }
    .section-title{
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: flex-start;
        padding: 10px 0;
        label{
            padding: 0 15px;
            font-size: 12px;
        }
        input{
            margin-left: 10px;
        }
    }
    .container-button-two{
        display: flex; 
        justify-content: space-between;
        align-items: center;
        min-height:50px;
        padding: 5px 0;
        z-index: 10;
        background: #ffff;
    }
    input{
        width: 70%;
        font-family: Poppins;
        font-size: 12px;
        padding: 15px;
        border-radius: 18px;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .button-two{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 12px;
        text-decoration: none;
        border-radius: 14px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 5px;
        font-size: 12px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        cursor: pointer;
        svg{
            font-size: 18px;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .windons-two{
        .windons-section-info{
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2,1fr);
            grid-template-areas: "seleccione-group"  "seleccione-employe";
            overflow-y: auto;
            grid-gap: 10px;
            height: 100%;
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.822);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(14.2px);
                -webkit-backdrop-filter: blur(14.2px);
                border-radius: 10px;
            }
            &::-webkit-scrollbar {
                width: 8px;
            }
            .section-info-two {
                max-height: 100%;
                height: 100%;
                .list-info {
                    min-height: 80vh;
                    height: 100%;
                }
            }
        }
        .section-title {
            margin-right: 10px;
        }
    }
}

@media screen and (max-width: 800px) {
    .windons-two{ 
        .windons-title-section p {
            font-size: 10px;
            margin-bottom: 2px;
        }
        .windons-title-section {
            display: grid;
            min-height: 50px;
            grid-template-columns: auto auto 105px;
            align-items: start;
        }
        input {
            font-size: 11px;
            padding: 8px;
            border-radius: 10px;
        }
        .section-title label {
            padding: 0 15px;
            font-size: 9px;
        }
        .windons-section-info .section-info-two .list-info{
            .item-produc{
                padding: 5px;
                .name{
                    font-size: 12px;
                }
                p{
                    font-size: 9px;
                }
                .img-item-product .avatar-img {
                    width: 5vh;
                    height: 5vh;
                }
                .img-item-product .avatar-letter {
                    width: 5vh;
                    height: 5vh;
                }
                svg {
                    font-size: 20px;
                } 
                &:hover{
                    svg{
                        font-size: 22px;
                    }
                }
            }
            .item-produc-selec{
                padding: 5px;
                .name{
                    font-size: 12px;
                }
                p{
                    font-size: 9px;
                }
                .img-item-product .avatar-img {
                    width: 5vh;
                    height: 5vh;
                }
                .img-item-product .avatar-letter {
                    width: 5vh;
                    height: 5vh;
                }
                svg {
                    font-size: 20px;
                }
                &:hover{
                    svg{
                        font-size: 22px;
                    }
                }
            }
        }
    }
}