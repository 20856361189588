@import "../../../../Constant.scss";

.equipo-admin{
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
        .title{
            display: flex;
        }
    }
    .form-contact{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 280px auto;
        grid-gap: 5%;
        .column-brand{
            display: grid;
            grid-gap: 10px;
            justify-items: center;
            .input-brand {
                margin-top: 50px;
                width: 250px;
                height: 250px;
                overflow: hidden;
                position: relative;
                margin-bottom: 40px;
                img{
                    border-radius: 40px;
                }
            }
        }
        .column-company{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                option{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-dat:nth-child(7) {
                grid-column: span 2;
                margin-top: 20px;
                margin-bottom: 30px;
                display: grid;
                grid-template-columns: repeat(3,1fr);
                grid-gap: 10%;
            }
            .input-date:nth-child(8) {
                grid-column: span 2;
            }
        }
    }
    .custom-file-upload{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 16px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 10px;
        font-size: 14px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        margin-bottom: 30px;
        cursor: pointer;
        svg{
            font-size: 22px;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 24px;
            }
        }
    }
}



.equipo-admin-create{
    padding: 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 40px;
    height: 90%;
    min-height: 74vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .form-contact-create{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 280px auto;
        grid-gap: 5%;
        .column-brand-create{
            display: flex;
            flex-direction: column;
            grid-gap: 10px;
            align-items: center;
            .input-brand {
                width: 250px;
                height: 250px;
                overflow: hidden;
                position: relative;
                margin-bottom: 30px;
                img{
                    border-radius: 40px;
                }
            }
        }
        .column-company-create{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                option{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-dat:nth-child(12) {
                grid-column: span 4;
            }
            .input-dat{
                display: grid;
                grid-gap: 10px;
                grid-template-columns: repeat(3,1fr);
            }
        }
    }
    .custom-file-upload{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 16px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 10px;
        font-size: 14px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        margin-bottom: 30px;
        cursor: pointer;
        svg{
            font-size: 22px;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 24px;
            }
        }
    }
}


@media screen and (max-width: 800px){
    .equipo-admin{
        .encabezado-list{
            .title{
                display: none;
            }
        }
        .form-contact-e{
            grid-template-columns: 1fr;
            .section-img{
                grid-gap: 10px;
                margin-bottom: 5px;
            }
            .column-form{
                padding: 20px 20px;
                .input-date input{
                    font-size: 12px;
                    padding: 8px 12px;
                    border-radius: 14px;
                }
                .input-date label {
                    padding: 0 12px;
                    font-size: 10px;
                }
                .input-date select {
                    font-size: 12px;
                    padding: 8px 12px;
                    border-radius: 14px;
                }
            }
        }
        .scroll-equip-admin{
            padding: 20px 15px 10px 15px;
        }
    }
}