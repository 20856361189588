@import "../../../../Constant.scss";


.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-5Pl-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns:auto 40%;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-5Pl-img section-5Pl-title";
        max-width: 1400px;
        width: 60%;
        min-height: 30vw;
        .section-5Pl-img {
            grid-area: section-5Pl-img ;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            max-height: 60vh;
            .img-5Pl {
                width: 100%;
                height: auto; 
                margin-top: -20px;
                max-width: 800px;
            }
        }
        .section-5Pl-title{
            grid-area: section-5Pl-title;
            display: grid;
            align-items: start;
            align-content: center;
            grid-gap: 20px;
            z-index: 99;
            text-align: start;
            grid-template-columns: 30px auto;
            margin-bottom: -70px;
            .section-5Pl-icon{
                width: 40px;
            }
            .title-page-5Pl{
                font-size: 30px;
                line-height: 40px;
                text-align: start;
                font-weight: 400;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
                color: $fontcolor;
                margin-bottom: 20px;
                span{
                    font-style: oblique;
                    font-weight: 700;
                }
            }
            .subtitle-page-5Pl{
                font-size: 16px;
                line-height: 25px;
                text-align: start;
                font-weight: 400;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
                color: $fontcolor;
                span{
                    font-weight: 600;
                }
            }
        }
    }
}


@media screen and (max-width: 1550px){
    .section-contect{
        .section-5Pl-contect-container{
            width: 80%;
        }
    }
}


@media screen and (max-width: 1350px){
    .section-contect .section-5Pl-contect-container {
        z-index: 1;
        display: grid;
        grid-template-columns: auto 50%;
        min-height: 45vh;
        margin-top: 5vw;
    }
}

@media screen and (max-height: 850px){
    .section-contect .section-5Pl-contect-container{ 
        min-height: 60vh;
        .section-5Pl-title {
            margin-bottom: -150px;
        }
    }
}


@media screen and (max-width: 1150px){
    .section-contect .section-5Pl-contect-container {
        grid-template-columns: auto 50%;
    }
}

@media screen and (max-width: 1050px){
    .section-contect{
        .section-5Pl-contect-container{
            margin: 20px 0;
            min-height: 35vh;
            grid-gap: 0px;
            grid-template-columns: auto 55%;
            .section-5Pl-title{
                grid-gap: 30px;
                .section-5Pl-icon{
                    width: 30px;
                }
                .title-page-5Pl{
                    font-size: 25px;
                    line-height: 27px;
                }
                .subtitle-page-5Pl{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .section-5Pl-img{
                .img-5Pl {
                    border-radius: 20px;
                    margin-bottom: 0px;
                    margin-top: 0px;
                }
            } 
        }
    }
}

@media screen and (max-width: 950px){
    .section-contect{
        .section-5Pl-contect-container{
            width: 90%;
        }
    }
}

@media screen and (max-width: 750px){
    .section-contect{
        .section-5Pl-contect-container{
            margin: 20px 0;
            min-height: 35vh;
            grid-template-columns: 1fr;
            grid-template-areas: "section-5Pl-img " "section-5Pl-title ";
            .section-5Pl-title{
                grid-gap: 0px;
                margin-bottom: 0px;
                .section-5Pl-icon{
                    width: 25px;
                }
                .title-page-5Pl{
                    font-size: 20px;
                    line-height: 22px;
                }
                .subtitle-page-5Pl{
                    font-size: 15px;
                    line-height: 16px;
                }
            }
            .section-5Pl-img{ 
                justify-content: center;
                align-items: center;
                .img-5Pl {
                    width: 90%;
                    height: auto;
                    max-width: 400px;
                }
            }
        }
    }
}