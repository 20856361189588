@import "../../../../Constant.scss";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";


.section-2-contect{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    max-width: 1400px;
    justify-items: center;
    justify-content: center;
    margin: 40px 0px;
    grid-gap: 50px;
    width: 95%;
    .section-2-title{
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        justify-items: start;
        color: $color6;
    }
    .container-button-nav{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .slider-2{
        width: 100%;
        display:grid;
        justify-content: center;
        align-items: center;
        align-content: center;
        .section-2-slider{
            max-width: 40vw;
            width: 100%;
            height: 100%;
            .img-section-2{
                width: 100%;
                height: auto;
                display: grid !important;
                align-content: center;
                justify-content: center;
                align-items: center;
                justify-items: center;
                img{
                    width: 80%;
                    height: auto;
                }
                p{
                    color: #ffffff00;
                }
            }
        }
        .slick-next:before{
            color: $color7 !important;
            margin-left: -30px !important;
        }
        .slick-prev:before{
            color: $color7 !important;
            margin-right: -30px !important;
        }
    }
}


@media screen and (max-width: 1100px){
    .section-2-contect{
        .section-2-title{
            padding: 50px 50px 50px 50px; 
        }
    }
}

@media screen and (max-width: 700px){
    .section-2-contect{
        grid-template-columns: 1fr;
        .section-2-title{
            padding: 50px 50px 50px 50px; 
        }
        .slider-2{
            .section-2-slider{
                max-width: 70vw;
            }
        }
    }
}