@import "../../../../Constant.scss";

.list-reserve{
    display: grid;
    align-content: space-between;
    height: 100%;
    // .contect-list-users{
    //     display: grid;
    //     grid-gap: 10px;
    //     .list{
    //         display: flex;
    //         flex-direction: column;
    //         height: 50vh;
    //         grid-gap: 10px;
    //         padding: 20px;
    //         overflow-y: auto; 
    //         overflow-x: hidden;
    //         /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    //         &::-webkit-scrollbar-thumb {
    //             background-color: rgba(169, 169, 169, 0.562);
    //             border-radius: 10px;
    //         }
    //         /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    //         &::-webkit-scrollbar-track {
    //             @extend %glass;
    //             border-radius: 10px;
    //         }
    //         /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    //         &::-webkit-scrollbar {
    //             width: 8px;
    //         }
    //     }
    //     .encabezado-list{
    //         display: flex;
    //         align-content: flex-start;
    //         align-items: flex-start;
    //         justify-content: space-between;
    //     }
    // }
    .input-search {
        font-size: 16px;
        color:$color7;
        @extend %glass1;
        padding: 14px 18px;
        border-radius: 18px;
        border: none;
        font-family: "Poppins", sans-serif;
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .pagination-numb{
        display: grid;
        justify-items: center;
    }
}
