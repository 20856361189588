@import "../../../../Constant.scss";

.iten-orden-user{
    display: grid;
    padding: 50px;
    border-radius: 50px;
    @extend %glass;
    margin:  30px 0 20px 0;
    gap: inherit;
    grid-gap: 20px;
    .detail-orden-date{
        display: grid;
        grid-template-columns: auto 380px;
        color: $color7;
        .orden-date{
            display: grid;
            justify-content: start;
            align-content: center;
            justify-items: start;
        }
        .orden-description{
            display: grid;
            justify-items: end;
            .date-orden{
                margin-top: 5px;
                padding: 10px;
                font-size: 12px;
                color: $color11;
                background: $color3;
                border-radius: 14px;
            }
            .date-sale{
                margin-top: 5px;
                padding: 10px;
                font-size: 12px;
                color: $color11;
                background: $color1;
                border-radius: 14px;
            }
            .date-cancel{
                margin-top: 5px;
                padding: 10px;
                font-size: 12px;
                color: $color11;
                background: $color10;
                border-radius: 14px;
            }
        }
    }
    .detail-orden-products{
        display: grid;
        grid-gap: 30px;
        color: $color7;
        .container-list-product{
            display: grid;
            grid-gap: 10px;
            .container-iten-produc{
                display: grid;
                grid-template-columns: 50px 40% auto;
                align-items: center;
                align-content: center;
                justify-items: stretch;
                grid-gap: 20px;
                padding: 5px;
                border-top: $color9 1px solid;
                img{
                    border-radius: 50%;
                    width: 50px;
                    height: 50px;
                }
                p{
                    font-size: 14px;
                    color: $color7;
                }
                .iten-produc-name{
                    text-align: center;
                }
                .iten-produc-sku{
                    text-align: end;
                }
            }
        }
        .container-orden-pintures{
            display: grid;
            grid-gap: 20px;
            .orden-pintures{
                display: grid;
                grid-gap: 30px;
                grid-template-columns: repeat(8,1fr);
                img{
                    border-radius: 30px;
                }
                .image-item{
                    position: relative;
                    img{
                        border-radius: 20px;
                    }
                    .delete-img{
                        top: -5px; 
                        left: -5px;
                        background: none;
                        border: none;
                        position: absolute;
                        svg{
                            cursor: pointer;
                            font-size: 18px;
                            padding: 3px;
                            border-radius: 50%;
                            color: $color11;
                            background: $color10;
                        }
                    }
                }
            }
            .input-load-imagen{
                display: flex;
                .custom-file-upload{
                    color: $color11;
                    background: #1E67E2;
                    border: #1E67E2 solid 2px;
                    padding: 8px 10px;
                    text-decoration: none;
                    border-radius: 14px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    grid-gap: 5px;
                    font-size: 10px;
                    @extend %trans;
                    justify-content: center;
                    font-family: $font;
                    cursor: pointer;
                    svg{
                        font-size: 16px;
                    }
                    &:hover{
                        color: #1E67E2;
                        background: none;
                        @extend %trans;
                        svg{
                            color: #1E67E2;
                        }
                    }
                }
                input[type="file"] {
                    display: none;
                }

            }
        }
    }
}


@media screen and (max-width: 1200px){
    .iten-orden-user{
        padding: 30px;
        border-radius: 30px;
        margin:  10px 0 20px 0;
        grid-gap: 10px;
        .detail-orden-date{
            grid-template-columns: auto 200px;
        }
        .detail-orden-products{
            .container-orden-pintures{
                grid-gap: 10px;
                .orden-pintures{
                    grid-gap: 10px;
                    grid-template-columns: repeat(4,1fr);
                    img{
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    .iten-orden-user{
        padding: 20px;
        .detail-orden-date{
            grid-template-columns: 1fr;
            .orden-date{
                .date-orden{
                    padding: 5px;
                    font-size: 10px;
                    border-radius: 8px;
                }
                .date-sale{
                    padding: 5px;
                    font-size: 10px;
                    border-radius: 8px;
                }
                .date-cancel{
                    padding: 5px;
                    font-size: 10px;
                    border-radius: 8px;
                }
            }
        }
        .detail-orden-products{
            .container-list-product{
                .container-iten-produc{
                    grid-template-columns: 50px auto;
                    img{
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                    }
                    p{
                        font-size: 11px;
                        color: $color7;
                    }
                    .iten-produc-name{
                        text-align: end;
                    }
                    .iten-produc-sku{
                        display: none;
                    }
                }
            }
            .container-orden-pintures{
                .orden-pintures{
                    grid-template-columns: repeat(2,1fr);
                    img{
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}