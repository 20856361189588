@import "../../../../Constant.scss";

.menu-header{
    z-index: 555;
    display: grid;
    @extend %glass;
    border-radius: 30px;
    align-items: center;
    grid-template-columns: auto 250px;
    padding: 5px 5px 5px 10px;
    grid-gap: 15px;
    min-height: 55px;
}

@media screen and (max-width: 750px){
    .menu-header{
        grid-template-columns: auto 140px;
    }
}