@import "../../../../Constant.scss";

.windons-manuals{
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: 100%;
    background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
    .windos-manuales-setion{
        display: flex;
        flex-direction: column;
        margin: 50px 5%;
        padding: 5%;
        background: rgba(255, 255, 255, 0.822);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(14.2px);
        -webkit-backdrop-filter: blur(14.2px);
        border-radius: 50px;
        .windons-manuals-menu{
            display:grid;
            grid-gap: 10px;
            a{
                text-decoration: none;
                padding: 5px 10px;
                background: rgba(255, 255, 255, 0.822);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(14.2px);
                -webkit-backdrop-filter: blur(14.2px);
                border-radius: 10px;
                color: $color2;
                transition-property: all;
                transition-duration: 0.2s;
                transition-timing-function: linear;
                font-size: 16px;
                &:hover{
                    color: $color7;
                    transition-property: all;
                    transition-duration: 0.2s;
                    transition-timing-function: linear;
                    font-size: 17px;
                }
            }
        }
        .windons-manuals-content-video{
            display: grid;
            justify-items: center;
            iframe{
                width: 600px;
                height: 320px;
                border-radius: 30px;
                margin-bottom: 30px;
            }
        }
    }
    .windos-manuales-encabezado{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            padding: 20px 20px 20px 10%;
            max-width: 100px;
            width: 15%;
            height: auto;
        }
    }
    .windons-manuals-title{
        font-size: min(5vw, 50px);
        line-height: min(5vw, 55px) ;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding: 0;
    }
    .windons-manuals-subtitle{
        font-size: min(4vw, 30px);
        line-height: min(4vw, 31px) ;
        font-weight: 400;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 650px){
    .windons-manuals{
        .windos-manuales-setion{
            padding: 30px;
            border-radius: 30px;
            margin: 10px 5% 50px 5%;
            .windons-manuals-menu{
                a {
                    font-size: 12px;
                    &:hover{
                        font-size: 13px;
                    }
                }
            }
            .windons-manuals-content-video{
                iframe {
                    width: 300px;
                    height: 170px;
                    border-radius: 20px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}


@media screen and (max-width: 400px){
    .windons-manuals{
        .windos-manuales-setion{
            padding: 20px;
            border-radius: 20px;
            .windons-manuals-content-video iframe {
                width: 180px;
                height: 100px;
                border-radius: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

.close-button-xx{
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 999;
    svg {
        font-size: 50px;
        color: $color8;
        padding: 10px;
        background-color: $color1;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Sombra ligera para dar profundidad.
        border-radius: 50%;
    }
}