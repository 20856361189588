@import "../../../../Constant.scss";

.item-reserve{
    display: grid;
    text-decoration: none;
    grid-template-columns: 45px 25% auto 20% 10% 180px;
    grid-gap: 50px 20px;
    color: $color6 ;
    grid-template-areas: "avatar name-user email phone rol date";
    align-items: center;
    align-content: center;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    color: $color7;
    justify-items: center;
    background: none;
    @extend %trans;
    @extend %glass;
    border-radius: 30px;
    .filter{
        display: flex;
        grid-gap: 5px;
        align-content: center;
        align-items: center;
        button{
            border: none;
            background: none;
            font-size: 14px;
            font-family: $font;
            color: $color7;
            cursor: pointer;
            &:hover{
                color: $color1;
            }
        }
        select{
            font-family: Poppins;
            font-size: 14px;
            padding: 2px 5px;
            border-radius: 16px;
            @extend %glass1;
            border: none; /* Agregado para eliminar el borde */
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
        }
    }
    .avatar {
        grid-area: avatar;
        border: none;
        background: none;
        font-family: $font;
        text-align: start;
        cursor: pointer;
        .avatar-letter{
            font-size: 20px;
            font-weight: 300;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            line-height: 0px;
            background: $color1;
            color: $color8;
            padding: 10px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
        }
        .avatar-img {
            width: 50px;
            height: 50px;
            border-radius: 50%; /* Esto crea un círculo */
            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
            position: relative; /* Permite centrar vertical y horizontalmente */
            .avatar-img img {
                width: 100%;
                height: 100%;
                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                object-position: center center; /* Centra la imagen */
            }
        }
    };
    .name-user{
        grid-area: name-user;
        font-weight: 600;
        text-align: center;
        font-size: 12px;
        color: $color6;
    }
    .email{
        grid-area: email;
        text-align: center;
        font-size: 12px;
        color: $color6;
    }
    .phone{
        grid-area: phone;
        text-align: center;
        font-size: 12px;
        color: $color6;
    }
    .rol{
        grid-area: rol;
        text-align: center;
        display: flex;
        font-size: 12px;
        color: $color6;
        .admin{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color1;
            background: $color1;
            border-radius: 10px;
            font-size: 13px;
        }
        .super-admin{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color10;
            background: $color10;
            border-radius: 10px;
            font-size: 13px;
        }
        .usuario{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color12;
            background: $color12;
            border-radius: 10px;
            font-size: 13px;
        }
    }
    .date{
        grid-area: date;
        text-align: end;
        font-size: 12px;
        color: $color6;
    }
    &:hover{
        background: $color1;
        @extend %trans;
        .name-user{
            color: $color11;
        }
        .email{
            color: $color11;
        }
        .phone{
            color: $color11;
        }
        .date{
            color: $color11;
        }
        .rol{
            color: $color11;
        }
    }
}

.title-user{
    display: grid;
    text-decoration: none;
    grid-template-columns: 45px 25% auto 20% 10% 180px;
    grid-gap: 50px 20px;
    color: $color6 ;
    grid-template-areas: "avatar name-user email phone rol date";
    align-items: center;
    align-content: center;
    padding: 10px 30px;
    font-size: 14px;
    text-align: center;
    color: $color7;
    justify-items: center;
    background: none;
    @extend %trans;
    .filter{
        display: flex;
        grid-gap: 5px;
        align-content: center;
        align-items: center;
        button{
            border: none;
            background: none;
            font-size: 14px;
            font-family: $font;
            color: $color7;
            cursor: pointer;
            &:hover{
                color: $color1;
            }
        }
        select{
            font-family: Poppins;
            font-size: 14px;
            padding: 2px 5px;
            border-radius: 16px;
            @extend %glass1;
            border: none; /* Agregado para eliminar el borde */
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
        }
    }
    .avatar {
        grid-area: avatar;
        border: none;
        background: none;
        font-family: $font;
        text-align: start;
        cursor: pointer;
        .avatar-letter{
            font-size: 20px;
            font-weight: 300;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            line-height: 0px;
            background: $color1;
            color: $color8;
            padding: 10px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
        }
        .avatar-img {
            width: 72px;
            height: 72px;
            border-radius: 50%; /* Esto crea un círculo */
            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
            position: relative; /* Permite centrar vertical y horizontalmente */
            .avatar-img img {
                width: 100%;
                height: 100%;
                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                object-position: center center; /* Centra la imagen */
            }
        }
    };
    .name-user{
        grid-area: name-user;
        font-weight: 600;
        text-align: center;
        font-size: 16px;
        color: $color6;
    }
    .email{
        grid-area: email;
        text-align: center;
        font-size: 12px;
        color: $color6;
    }
    .phone{
        grid-area: phone;
        text-align: center;
        font-size: 12px;
        color: $color6;
    }
    .rol{
        grid-area: rol;
        text-align: center;
        display: flex;
        font-size: 12px;
        color: $color6;
        .admin{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color1;
            background: $color1;
            border-radius: 10px;
            font-size: 13px;
        }
        .super-admin{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color10;
            background: $color10;
            border-radius: 10px;
            font-size: 13px;
        }
        .usuario{
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 5px;
            color: $color8;
            padding: 2px 5px;
            border: 1px solid $color12;
            background: $color12;
            border-radius: 10px;
            font-size: 13px;
        }
    }
    .date{
        grid-area: date;
        text-align: end;
        font-size: 12px;
        color: $color6;
    }
    &:hover{
        .name-user{
            color: $color11;
        }
        .email{
            color: $color11;
        }
        .phone{
            color: $color11;
        }
        .date{
            color: $color11;
        }
    }
}

