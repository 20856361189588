@import "../../../Constant.scss";


.body-quiz{
    display: grid;
    width: 100%;
    min-height: 100vh;
    justify-items: center;
    align-items: center;
    background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
    .contenct-body-quiz{
        display: grid;
        width: 80%;
        max-width: 700px;
        justify-content: center;
        justify-items: center;
        text-align: center;
        @extend %glass;
        padding: 40px;
        border-radius: 50px;
        grid-gap: 30px;
        .brand-quiz{
            img{
                max-width: 200px;
                width: 80%;
            }
        }
        .title-shofi-quiz{
            font-size: min(3.5vw, 30px);
            line-height: min(3.7vw, 32px);
            text-align: center;
            margin-bottom: -10px;
            color: $color7;
        }
        .link-shofi-quiz{
            font-size: min(2.5vw, 25px);
            line-height: min(2.6vw, 27px);
            color: $color1;
            a{
                text-decoration: none;
                font-weight: 400;
                color: $color1;
                &:hover{
                    color: $color2;
                }
            }
            
        }
        .button-shofi-quiz{
            color: $color11;
            background: #1E67E2;
            border: #1E67E2 solid 2px;
            padding: 10px 20px;
            text-decoration: none;
            border-radius: 16px;
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 10px;
            font-size: 20px;
            @extend %trans;
            justify-content: center;
            font-family: $font;
            margin-top: 10px;
            cursor: pointer;
            svg{
                font-size: 22px;
            }
            img{
                height: 85px;
                width: auto;
                margin: -30px;
                @extend %trans;
            }
            &:hover{
                color: #1E67E2;
                background: none;
                @extend %trans;

                svg{
                    font-size: 24px;
                }
                img{
                    height: 95px;
                    width: auto;
                    margin: -35px;
                    @extend %trans;
                }
            }
        }
        .dates-quiz{
            display: flex;
            flex-direction: column;
            width: 100%;
            grid-gap: 30px;
        }
        .form-dates-quiz{
            text-align: left;
            display: grid;
            width: 100%;
            grid-gap: 10px;
            grid-template-columns: repeat(2 , 1fr);
            .input-date{
                display: grid;
                text-align: start;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 11px;
                    color:$color7;
                }
                input{
                    width: 94%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 3%;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    width: 100%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 3%;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                    overflow-y: auto; 
                    overflow-x: hidden;
                    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(4, 20, 31, 0.253);
                        border-radius: 10px;
                    }
                    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                    &::-webkit-scrollbar-track {
                        @extend %glass;
                        border-radius: 10px;
                    }
                    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                    &::-webkit-scrollbar {
                        width: 8px;
                    }
                }
            }
        }
    }
}


.wimdons-quiz-img{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 98vw;
    min-height: 100vh;
    height: 100%;
    background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
    .wimdons-quiz-questions{
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 90%;
    }
    .title-result{
        text-align: center;
        max-width: 90%;
    }
}



.container-input-img-previ{
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4,1fr);
    margin-bottom: 30px;
    grid-gap: 20px;
    .input-img{
        position: relative;
        img{
            width: 100%;
            display: block;
            border-radius: 16px;
            width: 150px;
            height: auto;
        }
        .delete-img{
            position: absolute;
            top: -2px; 
            left: -5px;
            background: none;
            border: none;
            svg{
                cursor: pointer;
                font-size: 18px;
                padding: 3px;
                border-radius: 50%;
                color: $color11;
                background: $color10;
            }
        }
    }
}


@media screen and (max-width: 950px){
    .body-quiz{
        .contenct-body-quiz{
            width: 70%;
            margin: 30px 0;
            padding: 30px;
            border-radius: 20px;
            grid-gap: 20px;
            .dates-quiz {
                grid-gap: 10px;
            }
            .form-dates-quiz{
                grid-template-columns:1fr;
                grid-gap: 5px;
                .input-date{
                    label{
                        padding: 0 12px;
                        font-size: 10px;
                        color:$color7;
                    }
                    input{
                        width: 94%;
                        font-size: 12px;
                        padding: 5px 5%;
                        border-radius: 12px;
                    }
                    select{
                        width: 100%;
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 5px 5%;
                    }
                }
            }
            .button-shofi-quiz {
                padding: 5px 10px;
                border-radius: 12px;
                grid-gap: 8px;
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }
    .container-input-img-previ{
        grid-template-columns: repeat(2,1fr);
        .input-img{
            position: relative;
            img{
                width: 100%;
                display: block;
                border-radius: 16px;
                width: 100px;
                height: auto;
            }
        }
    }
    
    .wimdons-quiz-img{
        .title-result{
            font-size: 20px;
            line-height: 19px;
        }
    }
    .wimdons-quiz-questions .wimdons-quiz-questions-gallery .wimdons-quiz-questions-img {
        width: 60%;
        max-height: 450px;
        margin: 2%;
        border-radius: 30px;
    }
}