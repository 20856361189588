@import "../../../../Constant.scss";

.huella-carbono {
    display: flex;
    border-radius: 30px;
    align-items: center;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2Filustrasi-green-industri_ratio-16x9.jpg?alt=media&token=bc99f2f1-4caa-4441-aacd-d110d41d347c');
    background-size: cover; // Cubre completamente el div, ajustándose al tamaño
    background-position: center; // Centra la imagen de fondo
    height: 100%; // Ajusta esto según necesites
    width: 100%; // Ajusta esto según necesites
    min-height: 120px;
    @extend %glass1;
    p {
        padding: 40px;
        color: white; 
        font-size: 40px; 
        text-align: center;
        font-weight: 700;
        text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.692);
    }
}


@media screen and (max-width: 900px){
    .huella-carbono {
        min-height: 100px;
        p {
            padding: 20px;
            font-size: 30px; 
        }
    }
}

@media screen and (max-width: 750px){
    .huella-carbono {
        min-height: 100px;
        p {
            padding: 10px;
            font-size: 20px; 
        }
    }
}


@media screen and (max-width: 550px){
    .huella-carbono {
        min-height: 90px;
        p {
            padding: 10px;
            font-size: 16px; 
        }
    }
}
