@import "../../../../Constant.scss";


.kits-item{
    display: grid;
    width: 100%;
    justify-items: center;
    img{
        width: 80vw;
        max-width: 800px;
        margin: -5vw;
    }
}

