
@import "../../../Constant.scss";

.main-home{
    display: grid;
    min-height: 100vh;
    grid-gap: 2vw;
    background: #F6F6F6;
}

.ellipse-div {
    width:40%;
    height: 900px;
    opacity: 0.7;
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(78, 166, 214, 0.72), rgba(185, 218, 236, 0.27) 48.5%, rgba(249, 249, 249, 0) 99.5%);
    margin-left: -20vw;
}

.ellipse-div-1 {
    width:40%;
    height: 900px;
    opacity: 0.7;
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(78, 166, 214, 0.72), rgba(185, 218, 236, 0.27) 48.5%, rgba(249, 249, 249, 0) 99.5%);
    margin-left: -20vw;
}

.ellipse-div-2 {
    width:40%;
    height: 500px;
    opacity: 0.7;
    position:absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(104, 198, 226, 0.72), rgba(136, 235, 238, 0.27) 48.5%, rgba(249, 249, 249, 0) 99.5%);
    margin-right: -40vw;
}


.ellipse-div-3 {
    width:40%;
    height: 500px;
    opacity: 0.7;
    position:absolute;
    background: radial-gradient(50% 50% at 50% 50%, rgba(104, 198, 226, 0.72), rgba(136, 235, 238, 0.27) 48.5%, rgba(249, 249, 249, 0) 99.5%);
}