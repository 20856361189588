@import "../../../../Constant.scss";

.button-home{
    position: fixed;
    z-index: 999;
    top: 30px;
    left: 30px;
    cursor: pointer;
    @extend %trans;
    svg{
        color: $color5;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        background: $color8;
        border: 2px solid $color8;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color8;
            @extend %trans;
            background: none;
            border: 2px solid $color8;
        }
    }
}

.button-home2{
    position: fixed;
    z-index: 999;
    top: 30px;
    left: 30px;
    cursor: pointer;
    @extend %trans;
    svg{
        color: $color1;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        background: $color8;
        border: 2px solid $color8;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color8;
            @extend %trans;
            background: none;
            border: 2px solid $color8;
        }
    }
}