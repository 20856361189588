@import "../../../../Constant.scss";

.desktop-menu{
    display: flex;
    grid-gap: 30px;
    align-content: center;
    align-items: center;
    .button-navbar{
        font-family: $font;
        font-size: 15px;
        text-decoration: none;
        color:$fontcolor;
        font-weight: 500;
        @extend %trans;
        &:hover{
            font-size: 14px;
            color: $color2;
            @extend %trans;
        }
    }
    .button-color{
        cursor: pointer;
        display: flex;
        font-family: $font;
        font-size: 15px;
        text-decoration: none;
        color:$color8;
        background: $color2;
        padding: 10px 20px;
        border-radius: 20px;
        border: 2px solid $color2;
        font-weight: 500;
        @extend %trans;
        grid-gap: 10px;
        align-content: center;
        align-items: center;
        &:hover{
            color: $color2;
            @extend %trans;
            background: $color8;
            border: 2px solid $color2;
        }
    }
}

@media screen and (max-width: 800px){
    .desktop-menu{
        display: none;
    }
}
