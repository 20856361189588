@import "../../../../Constant.scss";

.section-support{
    display: grid;
    max-height: 70vh;
    .form-scroll{
        overflow-y: auto;
        max-height: 55vh;
        padding: 0 20px;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
}

.input-load input[type="file"] {
    display: none;
}
.input-load{
    display:flex ;
    .custom-file-upload {
        color: $color11;
        background: $color2;
        border: $color2 solid 2px;
        padding: 10px;
        text-decoration: none;
        border-radius: 12px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 5px;
        font-size: 11px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        margin-bottom: 10px;
        cursor: pointer;
        svg{
            font-size: 14px;
        }
        &:hover{
            color:$color2;
            background: none;
            @extend %trans;
        }
    }
}

.container-input-img{
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(5,1fr);
    margin-bottom: 30px;
    grid-gap: 10px;
    .input-img{
        position: relative;
        overflow: hidden;  /* Asegura que el contenido que exceda los bordes del contenedor sea cortado */
        border-radius: 16px;
        min-height: 100px;
        img{
            width: 100% !important;
            display: block;
            object-fit: cover; /* Ajusta la imagen para cubrir el área designada, respetando el aspecto ratio */
            height: 100%; 
        }
        .delete-img{
            position: absolute;
            top: -2px; 
            left: -5px;
            background: none;
            border: none;
            svg{
                cursor: pointer;
                font-size: 18px;
                padding: 3px;
                border-radius: 50%;
                color: $color11;
                background: $color10;
            }
        }
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 0px;
    }
}

.container-support{
    display: grid;
    width: 100%;
    padding: 20px;
    color:$color6;
    text-align: center;
    grid-gap: 10px;
    background: $color8;
    border-radius: 50px;
    margin: 20px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
    span{
        color: $color2;
    }
    .brand{
        display: grid;
        justify-items: center;
        justify-content: center;
        img{
            width: 80%;
            max-width: 150px;
        }
    }
    .form-equipo{
        width: 100%;
        max-width: 500px;
        max-height: 60vh;
        .form-scroll1{
            overflow-y: auto;
            max-height: 40vh;
            padding: 0 30px;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
        }
        .input-date{
            display: grid;
            width: 95%;
            label{
                padding: 0 15px;
                font-size: 11px;
                color:$color6;
                text-align: left;
            }
            input{
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                width: 100%;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            select{
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .container-input-img1{
            display: grid;
            justify-items: center;
            grid-template-columns: repeat(3,1fr);
            margin-bottom: 30px;
            grid-gap: 10px;
            .input-img{
                position: relative;
                img{
                    max-width: 100px;
                    width: 100%;
                    display: block;
                    border-radius: 16px;
                }
                .delete-img{
                    position: absolute;
                    top: -2px; 
                    left: -5px;
                    background: none;
                    border: none;
                    svg{
                        cursor: pointer;
                        font-size: 18px;
                        padding: 3px;
                        border-radius: 50%;
                        color: $color11;
                        background: $color10;
                    }
                }
            }
        }
        .input-message{
            display: grid;
            width: 95%;
            label{
                padding: 0 15px;
                font-size: 11px;
                color:$color6;
                text-align: left;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                width: 97%;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-load2 input[type="file"] {
            display: none;
        }
        .input-load2{
            display:flex ;
            margin-top: 10px;
            .custom-file-upload {
                color: $color11;
                background: $color2;
                border: $color2 solid 2px;
                padding: 10px 10px;
                text-decoration: none;
                border-radius: 16px;
                display: flex;
                align-content: center;
                align-items: center;
                grid-gap: 10px;
                font-size: 12px;
                @extend %trans;
                justify-content: center;
                font-family: $font;
                margin-bottom: 30px;
                cursor: pointer;
                svg{
                    font-size: 18px;
                }
                &:hover{
                    color:$color2;
                    background: none;
                    @extend %trans;
                    svg{
                        font-size: 20px;
                    }
                }
            }
        }
        .cotainer-button2{
            display: grid;
            padding: 5px;
        }
        .button-form{
            font-family: Poppins;
            font-size: 16px;
            padding: 8px;
            font-weight: 700;
            color: $color8;
            border: 2px solid $color2;
            border-radius: 16px;
            background: $color2;
            @extend %trans;
            cursor: pointer;
            margin-top: 5px;
            &:hover{
                font-weight: 700;
                color: $color2;
                border: 2px solid $color2;
                border-radius: 16px;
                background: none;
                @extend %trans;
            }
        }
        .button-form2{
            font-family: Poppins;
            font-size: 16px;
            padding: 8px;
            font-weight: 700;
            color: $color1;
            border: 2px solid $color8;
            border-radius: 16px;
            background: $color8;
            @extend %trans;
            cursor: pointer;
            margin-top: 5px;
            &:hover{
                font-weight: 700;
                color: $color8;
                border: 2px solid $color8;
                border-radius: 16px;
                background: none;
                @extend %trans;
            }
        }
    }
}