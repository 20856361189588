@import "../../../../Constant.scss";

.menu-left{
    display: grid;
    width: 100%;
    height: 100%;
    max-height: 95vh;
    grid-template-rows: 9vh auto auto;
    align-content: space-between;
    .brand-menu-left{
        display: grid;
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        .logo-shofi{
            display: flex;
            width: 70%;
        }
        .icon-shofi{
            display: none;
            width: 80%;
        }
    }
    .brand-left{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-items: center;
        justify-content: center;
        align-items: center;
        align-content: center;
        @extend %glass;
        padding: 5px 15px;
        border-radius: 30px;
        grid-gap: 20px;
        min-height: 55px;
        .logo-shofi{
            display: flex;
            width: 90%;
            padding: 10px;
            max-width: 40px;
        }
        .logo-shofi2{
            display: flex;
            width: 90%;
            padding: 10px;
            border-radius: 25px;
            @extend %glass;
        }
        .icon-shofi{
            @extend %glass;
            display: none;
            width: 80%;
            padding: 20px;
            border-radius: 25px;
        }
    }
    .logout-left{
        display: grid;
        color: $color7;
        font-size: 11px;
        justify-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 1400px){
    .menu-left{
        .brand-menu-left{
            .logo-shofi{
                display: none;
            }
            .icon-shofi{
                display: flex;
            }
        }
        .brand-left{
            padding: 5px 20px;
            grid-template-columns: 1fr;
            .logo-shofi{
                display: none;
            }
            .logo-shofi2{
                display: none;
            }
            .icon-shofi{
                display: flex;
                width: 100%;
                padding: 10px;
                border-radius: 20px;
            }
        }
    }
}