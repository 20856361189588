@import "../../../../Constant.scss";

.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-0-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        min-height: 60vh;
        position: relative;
        grid-template-areas: "section-0-title section-0-img ";
        margin-top: 130px;
        max-width: 1400px;
        grid-gap: 5%;
        width: 90%;
    }
    .section-0-title{
        grid-area: section-0-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        justify-items: start;
        .container-button-nav{
            width: 100%;
            display: flex;
            justify-content: flex-end;
            position: relative;
        }
    }
    .section-0-img {
        grid-area: section-0-img ;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .img-0 {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
}

@media screen and (max-width: 900px){
    .section-contect{
        .section-0-contect-container{
            grid-template-columns: 1fr;
            grid-template-areas: "section-0-img" "section-0-title ";
            margin-top: 110px;
        }
    }
}