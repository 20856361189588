@import "../../../../Constant.scss";




.list-product-asignate{
    display: grid;
    align-content: space-between;
    height: 100%;
    .contect-list-product-asignate{
        display: grid;
        grid-gap: 10px;
        .list{
            display: flex;
            flex-direction: column;
            height: 50vh;
            grid-gap: 10px;
            padding: 20px;
            overflow-y: auto; 
            overflow-x: hidden;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
        }
        .encabezado-list{
            display: flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
}

.list-product-reasignate{
    display: grid;
    align-content: space-between;
    height: 100%;
    .contect-list-product-reasignate{
        display: grid;
        grid-gap: 10px;
        .encabezado-list-reasignate{
            display: flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-between;
        }
        .list-reasignate{
            display: flex;
            flex-direction: column;
            height: 65vh;
            grid-gap: 10px;
            padding: 20px;
            overflow-y: auto; 
            overflow-x: hidden;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
        }
    }
    .pagination-numb-reasignate{
        display: grid;
        justify-items: center;
        padding: 5px 0px;
    }
}