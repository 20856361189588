@import "../../../../Constant.scss";

.button-download{
    font-family: Poppins;
    font-size: 18px;
    padding: 8px;
    font-weight: 700;
    color: $color1;
    border: 2px solid $color8;
    border-radius: 16px;
    background: $color8;
    @extend %trans;
    cursor: pointer;
    margin-top: 5px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    svg{
        font-size: 28px;
    }
    &:hover{
        font-weight: 700;
        color: $color8;
        border: 2px solid $color8;
        border-radius: 16px;
        background: none;
        @extend %trans;
    }
}

.button-volver{
    font-family: Poppins;
    font-size: 16px;
    padding: 5px;
    font-weight: 700;
    color: $color8;
    border: 2px solid $color8;
    border-radius: 16px;
    background: $color1;
    @extend %trans;
    cursor: pointer;
    margin-top: 5px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    svg{
        font-size: 22px;
    }
    &:hover{
        font-weight: 700;
        color: $color1;
        border: 2px solid $color8;
        border-radius: 16px;
        background: $color8;
        @extend %trans;
    }
}