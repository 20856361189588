@import "../../../../Constant.scss";

.orden-item{
    .orden-item-info{
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 140px auto 220px;
        @extend %glass;
        padding: 20px 30px 20px 20px;
        border-radius: 40px;
        margin: 10px 20px;
        img{
            border-radius: 30px;
            width: 120px;
            height: 120px;
        }
        .contect-orden-item-info{
            display: grid;
            align-content: space-between;
            padding: 10px 0;
            p{
                font-size: 14px;
                color: $color7;
            }
            .orden-item-info-date{
                display: grid;
                justify-content: start;
                .date-sale{
                    margin-top: 5px;
                    padding: 10px;
                    font-size: 12px;
                    color: $color11;
                    background: $color3;
                    border-radius: 14px;
                }
                .date-upade{
                    margin-top: 5px;
                    padding: 10px;
                    font-size: 12px;
                    color: $color11;
                    background: $color1;
                    border-radius: 14px;
                }
            }
        }
        .logisty-orden-item-info{
            display: grid;
            align-items: center;
            justify-items: end;
            align-content: space-between;
            padding: 10px 0;
        }
    }
}


.windons-admin-two{
    padding: 2vh 2vh 0% 2vh;
    display: grid;
    grid-template-rows: 8vh 68vh 8vh;
    .img-item-product{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .windons-title-section{
        display: grid;
        min-height: 150px;
        grid-template-columns: auto auto 150px;
        align-items: start;
        p{
            font-size: 12px;
            margin-left: 10px;
            color: $color7;
            margin-bottom: 5px;
        }
        .windons-title-info{
            display: flex;
            .input-date{
                display: grid;
                width: 90%;
                padding: 0 5% 0 0;
                label{
                    padding: 0 12px;
                    font-size: 10px;
                    color:$color7;
                }
                input{
                    width: 95%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 15px;
                    border-radius: 16px;
                    border: none;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 8px 10px;
                    border-radius: 12px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
        }
    }
    .windons-section-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 10px;
        width: 100%;
        grid-template-areas: "seleccione-group  seleccione-employe";
        .section-info{
            max-height: 95%;
            margin-bottom: 5%;
            p{
                font-size: 12px;
            }
            .list-info{
                display: flex;
                flex-direction: column;
                max-height: 90%;
                overflow-y: auto;
                grid-gap: 10px;
                margin-bottom: 20px;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-produc{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    cursor: pointer;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            padding: 10px;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
                .item-kits-as{
                    display: grid;
                    grid-template-columns: 75px auto 100px 40px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 30px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .contect-seller-item-kits{
                        display: grid;
                        text-align: center;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            padding: 10px;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
        .section-info1 {
            @extend %glass;
            padding: 20px;
            border-radius: 40px;
            h2 {
                margin: 20px 15px;
            }
            .input-date {
                display: grid;
                width: 100%;
                label {
                    padding: 0 12px;
                    font-size: 10px;
                    color: $color7;
                }
                input,
                select,
                textarea,
                option { // Añadido option aquí también
                    width: 100%; // Asegurar que todos tomen el 100% del ancho
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 15px;
                    border-radius: 14px;
                    @extend %glass1;
                    border: none;
                    box-sizing: border-box; 
                    &:focus, &:active {
                        outline: none;
                    }
                }
                textarea {
                    resize: vertical;
                    box-sizing: border-box;
                    min-height: 15vh;
                    margin-bottom: 10px;
                }
            }
        }
        .seleccione-group{
            grid-area: seleccione-group;
        }
        .seleccione-employe{
            grid-area: seleccione-employe;
        }
    }
    .windons-section-group{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 30px;
        width: 100%;
        .section-info-group{
            max-height: 100%;
            margin-bottom: 5%;
            .list-info-group{
                margin-top: 1vh;
                display: flex;
                flex-direction: column;
                max-height: 70vh;
                overflow-y: auto;
                grid-gap: 10px;
                p{
                    font-size: 12px;
                }
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-kits-group{
                    text-decoration: none;
                    display: grid;
                    grid-template-columns: 75px auto 150px 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .contect-seller-item-kits{
                        text-align: center;
                        display: grid;
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 30px;
                            @extend %trans;
                        }
                    }
                }
                .item-quiz-group{
                    text-decoration: none;
                    display: grid;
                    grid-template-columns: 75px auto 150px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .contect-seller-item-kits{
                        text-align: center;
                        display: grid;
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 30px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
    }
    .section-title{
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 10px 0;
    }
    .container-button{
        display: flex;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    input{
        width: 70%;
        font-family: Poppins;
        font-size: 12px;
        padding: 15px;
        border-radius: 18px;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .button-eidicion{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 8px;
        text-decoration: none;
        border-radius: 14px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 5px;
        font-size: 12px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        cursor: pointer;
        svg{
            font-size: 18px;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 20px;
            }
        }
    }
}

.wimdons-emerge-detail{
    width: 100%;
    height: 100%;
    position: fixed;
    @extend %glass;
    z-index: 999;
    border-radius: 30px;
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    .emerge-detail{
        max-width: 800px;
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(2,1fr);
        grid-template-areas: 
        "emerge-detail-img   emerge-detail-contect";
        margin: 40px;
        .emerge-detail-img{
            grid-area: emerge-detail-img;
            img{
                border-radius: 40px;
            }
        }
        .emerge-detail-contect{
            grid-area: emerge-detail-contect;
            display: grid;
            grid-template-columns: 1fr;
            align-content: space-between;
            .text-emerge{
                text-align: justify;
                h4{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    line-height: 11px;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 18px;
                    line-height: 19px;
                }
                span{
                    font-weight: 700;
                }
            }
            .emerge-detail-button{
                display: flex;    
                justify-content: space-around;
            }
        }
    }
    .emerge-detail2{
        max-width: 800px;
        display: grid;
        grid-gap: 40px;
        grid-template-columns:1fr;
        .emerge-detail-contect{
            grid-area: emerge-detail-contect;
            display: grid;
            grid-template-columns: 1fr;
            align-content: space-between;
            .text-emerge{
                text-align: justify;
                h4{
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    margin: 0;
                    padding: 0;
                    font-size: 20px;
                    line-height: 11px;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 18px;
                    line-height: 19px;
                }
                span{
                    font-weight: 700;
                }
            }
            .emerge-detail-button{
                display: flex;    
                justify-content: space-around;
            }
        }
    }
}

@media screen and (max-width: 850px){
    .wimdons-emerge-detail .emerge-detail {
        max-width: 500px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-template-areas:
        "emerge-detail-img"
        "emerge-detail-contect";
        margin: 20px;
        max-height: 80vh;
        overflow-y: auto;
        padding: 0 20px;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .emerge-detail-img{
            display: grid;
            justify-items: center;
            img {
            border-radius: 30px;
            max-width: 250px;
            }
        }
        .emerge-detail-contect{
            display: grid;
            grid-gap: 15px;
            .text-emerge{
                display: grid;
                grid-gap: 5px;
                p {
                    font-size: 12px;
                    line-height: 12px;
                }
                h4{
                    font-size: min(3vw, 22px);
                    line-height: min(3.3vw, 25px);
                    font-weight: 800;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    margin: 0;
                    padding: 0;
                }
            } 
        }
    }
}


.list-info-product{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding: 5px 0 20px 0;
    overflow-y: auto;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(169, 169, 169, 0.562);
        border-radius: 10px;
    }
    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
   
}






@media screen and (max-width: 1200px){
    .windons-admin-two{
        .windons-section-info{
            .section-info{
                .list-info {
                    .item-kits-as{
                        grid-template-columns: 50px auto 0px 25px;
                        padding: 8px;
                        margin: 0 10px 0 0;
                        border-radius: 25px;
                        .name{
                            font-size: 12px;
                        }
                        p{
                            font-size: 10px;
                        }
                        svg{
                            font-size: 20px;
                        }
                        .search{
                            font-size: 20px;
                        }
                        &:hover{
                            svg{
                                color: $color1;
                                background: $color11;
                                font-size: 24px;
                                @extend %trans;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .windons-admin-two {
        .windons-title-section{
            grid-template-columns:70% auto 120px;
            justify-content: end;
            justify-items: stretch;
            justify-content: space-between;
            p{font-size: 10px;}
        }
        .windons-section-group .section-info-group {
            .list-info-group {
                .item-kits-group{
                    border-radius: 16px;
                    grid-template-columns: 55px auto 30px;
                    .default-seller{
                        display: none;
                    }
                    .name{
                        font-size: 12px;
                    }
                    p{
                        font-size: 10px;
                    }
                }
                .item-quiz-group {
                    grid-template-columns: 55px auto;
                    .name{
                        font-size: 12px;
                    }
                    p{
                        font-size: 10px;
                    }
                    .date{
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .windons-admin-two {
        .img-item-product {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        .windons-section-info .section-info .list-info .item-produc {
            grid-template-columns: 60px auto 45px;
            .img-item-product .avatar-letter{
                height: 35px;
                width: 35px;
            }
            .img-item-product .avatar-img{
                width: 52px;
                height: 52px;
            }
        }
    }
    .windons-admin-two .windons-section-info .section-info .list-info .item-produc-selec {
        grid-template-columns: 60px auto 45px;
        .img-item-product .avatar-letter{
            height: 35px;
            width: 35px;
        }
        .img-item-product .avatar-img{
            width: 52px;
            height: 52px;
        }
    }
    .windons-admin-two .windons-section-info {
        grid-gap: 20px;
    }
}

@media screen and (max-width: 950px){
    .windons-admin-two{
        .windons-title-section{
            grid-template-columns:auto auto 120px;
        }
        .windons-title-section .windons-title-info{
            display: none;
        }
        .title-info{
            display: none;
        }
        .windons-section-info{
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas: "seleccione-employe" "seleccione-group";
            max-height: 65vh;
            overflow-y: auto;
            grid-gap: 10px;
            margin-bottom: 10px;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                background: rgba(255, 255, 255, 0.822);
                box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(14.2px);
                -webkit-backdrop-filter: blur(14.2px);
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
            .section-info{
                max-height: 100%;
                margin-bottom: 20px;
                padding: 0;
            }
            .section-info1{
                padding: 0px 20px;
                border-radius: 0px;
                background: none;
                box-shadow:none;
                backdrop-filter:none;
                -webkit-backdrop-filter: none;
            }
            .section-title{
                padding: 10px 10px 10px 0;
            }
        }
        .container-button {
            margin-top: 0px;
        }
    }
}

@media screen and (max-width: 550px){
    .windons-admin-two .windons-section-info .section-info .list-info .item-produc {
        grid-template-columns: 45px auto 20px;
        border-radius: 25px;
        .name{
            font-size: 12px;
        }
        p{
            font-size: 10px;
        }
        svg{
            font-size: 20px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
        }
    }
    .windons-admin-two .windons-section-info .section-info .list-info  .item-produc-selec{
        grid-template-columns: 45px auto 30px;
        border-radius: 25px;
        .name{
            font-size: 12px;
        }
        p{
            font-size: 10px;
        }
        svg{
            font-size: 20px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
        }
    }
}