@import "../../../../Constant.scss";

header{
    display: flex;
    width: 100%;
    position: fixed;
    z-index: 999;
    justify-content: center;
    .container-menu{
        margin-top: 10px;
        display: flex;
        @extend %glass;
        align-items: center;
        width: 90%;
        border-radius: 30px;
        max-width: 1400px;
        padding: 0px 20px;
        justify-content: space-between;
        .brand-menu{
            padding: 10px 30px;
            .brand{
                width: 70px;
            }
        }
    }
}

@media screen and (max-width: 900px){
    header{
        .container-menu{
            width: 85%;
            z-index: 55;
            .brand-menu{
                padding: 10px;
            }
        }
    }
}