@import "../../../../Constant.scss";

.contect-users-orden{
    display: flex;
    flex-direction: column;
    .date-contect-users-orden{
        display: flex;
        justify-content: center;
        grid-gap: 5%;
        .input-date-orden{
            display: grid;
            width: 100%;
            label{
                padding: 0 15px;
                font-size: 13px;
                color:$color7;
            }
            input{
                width: 96%;
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 2%;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }        
    }
}

.container-botom-resport{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 10px;
}
.contect-users-orden{
    display: flex;
    grid-gap: 20px;
    flex-direction: column;
    .container-contect-resport{
        display: flex;
        grid-gap: 20px;
        flex-direction: column;
        .container-title-resport{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .container-title-resport-button{
                display: flex;
                grid-gap: 10px;
            }
        }        
    }
    .buttom-orden-container{
        display: grid;
        justify-content: center;
        justify-items: center;
    }
}


.selec-column{
    color: $color7;
    background: #f1f1f1;
    border: $color7 solid 1px;
    padding: 8px 10px;
    text-decoration: none;
    border-radius: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 12px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    &:hover{
        color: $color11;
        border: $color7 solid 1px;
        background: $color7;
        @extend %trans;
    }
}


@media screen and (max-width: 850px){
    .selec-column{
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 13px;
    }
}


.onselec-column{
    color: $color13;
    background: #f0f7ea;
    border: $color13 solid 1px;
    padding: 8px 10px;
    text-decoration: none;
    border-radius: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 12px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    &:hover{
        color: $color11;
        border: $color13 solid 1px;
        background: $color13;
        @extend %trans;
    }
}


@media screen and (max-width: 850px){
    .onselec-column{
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 13px;
    }
}


