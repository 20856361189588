@import "../../../Constant.scss";

.fondo-form{
    background-size: cover;
    background-position: center;
    min-width: 100%;
    min-height: 100vh;
}

.space-siigo{
    height: 80px;
}