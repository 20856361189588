@import "../../../Constant.scss";

.main-form2{
    position: fixed;
    display: grid;
    min-width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 9999;
    max-width: 100vh;
    align-items: center;
    justify-items: center;
    overflow-y: auto; 
    overflow-x: hidden;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(169, 169, 169, 0.562);
        border-radius: 10px;
    }
    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
}

.main-form{
    position: fixed;
    display: grid;
    min-width: 100%;
    min-height: 100vh;
    background: $color5;
    z-index: 9999;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
}