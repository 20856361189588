@import "./Constant.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



h1{
  padding: 0;
  margin: 0;
}

.main-admin{
  display: grid;
  min-height: 100vh;
  grid-template-columns: 230px auto;
  background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
  .container-menu{
      display: grid;
      padding: 3vh 10px 0px 20px;
  }
  .container-admin{
      display: grid;
      margin: 3vh 30px 0px 0px;
      grid-gap: 1vh;
      grid-template-rows:9vh 84vh;
      max-height: 100%;
      .windows-admin{
        @extend %glass;
        max-height: 87vh;
        height: 87vh;
        border-radius: 30px 30px 0px 0px;
      }
  }
}

@media screen and (max-width: 1400px){
  .main-admin{
    grid-template-columns: 110px auto;
  }
}
@media screen and (max-width: 550px){
  .main-admin{
    grid-template-columns: auto;
    .container-menu{
      display: none;
    }
    .container-admin{
      margin: 3vh 20px 0px 20px;
      grid-gap: 2vh;
      grid-template-rows: 8vh 87vh;
    }
  }
}

body .Toastify .Toastify__toast {
  border-radius: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 12px !important;
}

body .Toastify .Toastify__toast-theme--light {
  border-radius: 16px !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 12px !important;
}
