@import "../../../../Constant.scss";


.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-0N-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: 55% auto;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-0N-title section-0N-img";
        margin-top: 130px;
        max-width: 1400px;
        grid-gap: 30px;
        width: 90%;
    }
    .section-0N-title{
        grid-area: section-0N-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        z-index: 99;
        text-align: start;
        .title-page-nosotros{
            font-size: 50px;
            text-align: start;
            font-weight: 500;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $color2;
            span{
                font-style: oblique;
                font-weight: 700;
            }
        }
        .subtitle-page-nosotros{
            font-size: 28px;
            line-height: 60px;
            text-align: start;
            font-weight: 400;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $fontcolor;
            .span1 {
                border: 2px solid $color2;
                padding: 0 15px;
                border-radius: 30px;
                margin: 5px;
            }
            .span2 {
                color: $color2;
                font-style: oblique;
                font-weight: 600;
                margin: 5px;
            }
            .span3{
                color:$color8;
                background: $color2;
                padding: 0 15px;
                border-radius: 30px;
                margin: 5px;
                font-style: oblique;
                font-weight: 500;
            }
        }
        
        
    }
    
    .section-0N-img {
        grid-area: section-0N-img ;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 60vh;
        .img-0N {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
}

@media screen and (max-width: 780px){
    .section-contect{
        .section-0N-contect-container{
            grid-template-columns: 1fr;
            grid-template-areas: "section-0N-img"  "section-0N-title";
        }
        .section-0N-img .img-0N {
            border-radius: 30px;
        }
    }
}