@import "../../../../Constant.scss";

.list-users{
    display: grid;
    align-content: space-between;
    height: 100%;
    .title-equip-list{
        display: flex;
    }
    .contect-list-users{
        display: grid;
        grid-gap: 10px;
        .encabezado-list{
            display: flex;
            align-content: flex-start;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
    .pagination-numb{
        display: grid;
        justify-items: center;
    }
}
