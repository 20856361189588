@import "../../../../Constant.scss";

.section-sec-status{
    display: grid;
    align-items: start;
    grid-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    .sec-status{
        display: grid;
        @extend %glass;
        border-radius: 40px;
        padding: 40px 10px;
        grid-gap: 10px;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        svg{
            font-size: 40px;
            padding: 25px;
            border-radius: 50%;
            color: $color11;
            text-shadow:0 1px 5px rgba(0, 0, 0, 0.459);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.253);
        }
        img{
            width: 75px;
            height: 75px;
            padding: 15px;
            border-radius: 50%;
            box-shadow: 0 2px 10px rgba(116, 116, 116, 0.253);
        }
        p{
            font-size: 11px;
            color: $color7;
        }
        .cant-sec{
            font-size: 40px;
            font-weight: 800;
            color: $color6;
        }
        .silla{
            background: $color1;
        }
        .escritorio{
            background: $color3;
        }
        .accesorio{
            background: $color4;
        }
        .computador{
            background: $color5;
        }
    }
}


@media screen and (max-width: 900px){
    .section-sec-status{
        grid-gap: 10px;
        grid-template-columns: repeat(2, 1fr);
    }
}



@media screen and (max-width: 500px){
    .section-sec-status{
        grid-gap: 10px;
        .sec-status{
            padding: 30px 10px;
            border-radius: 30px;
            img{
                width: 45px;
                height: 45px;
            }
            .cant-sec{
                font-size: 35px;
            }
        }
    }
}