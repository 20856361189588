@import "../../../../Constant.scss";

.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-0Pl-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: auto 52%;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-0Pl-img  section-0Pl-title ";
        margin: 130px 0px 50px 0px; 
        max-width: 1400px;
        grid-gap: 50px;
        width: 90%;
    }
    .section-0Pl-title{
        grid-area: section-0Pl-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        z-index: 99;
        text-align: start;
        .title-page-0pl{
            font-size: 50px;
            line-height: 65px;
            text-align: start;
            font-weight: 500;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color:$fontcolor;
            span{
                font-style: oblique;
                color: $color2;
            }
        }
        .subtitle-page-0pl{
            font-size: 18px;
            line-height: 25px;
            text-align: start;
            font-weight: 400;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color:$fontcolor;
            span{
                font-weight: 600;
            }
        }
        .container-button-nav-0pl{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    .section-0Pl-img {
        grid-area: section-0Pl-img ;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 60vh;
        .img-0Pl {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
    .title2-page-0pl{
        font-size: 40px;
        line-height: 52px;
        text-align: center;
        font-weight: 400;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin:  50px 0 0 0;
        padding: 0;
        width: 80vw;
        max-width: 800px;
        color:$fontcolor;
        .span1pl{
            font-style: oblique;
            color: $color2;
            font-weight: 600;
        }
        .span2pl{
            font-style: oblique;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 780px){
    .section-contect{
        .section-0Pl-contect-container{
            grid-template-columns: 1fr;
            grid-template-areas: "section-0Pl-img"  "section-0Pl-title";
        }
        .section-0Pl-img .img-0Pl {
            border-radius: 30px;
        }
    }
}

@media screen and (max-width: 510px){
    .section-contect{
        .section-0Pl-contect-container {
            grid-gap: 20px;
        }
        .section-0Pl-title{
            grid-gap: 25px;
            .title-page-0pl{
                font-size: 30px;
                line-height: 32px;
            }
            .subtitle-page-0pl{
                font-size: 15px;
                line-height: 18px;
            }
        }
        .title2-page-0pl{
            font-size: 20px;
            line-height: 21px;
        }
    }
}