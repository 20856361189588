@import "../../../../Constant.scss";

.movile-menu-admin{
    padding-top: 50px;
    z-index: 555;
    position: fixed;
    height: 100vh;
    width: 100%;
    display: none;
    align-items: center;
    justify-items: center;
    align-content: center;
    grid-gap: 10px;
    top:-30px;
    left: -30px;
    padding: 0 30px;
    background: #fffffff5;
}

@media screen and (max-width: 550px){
    .movile-menu-admin{
        display: grid;
    }
}