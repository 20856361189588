
@import "../../../../Constant.scss";

.button-logout{
    display: flex;
    grid-gap: 10px;
    font-family: $font;
    font-size: $fsize;
    text-decoration: none;
    color:$color8;
    font-weight: 500;
    @extend %trans;
    background:$color1;
    padding: 15px 20px;
    border-radius: 15px;
    border: solid 2px $color1;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    p{
        display: flex;
    }
    .logout{
        display: flex;
        align-items: center;
        grid-gap: 10px;
        svg{
            font-size: 20px;
        }
    }
    &:hover{
        color: $color1;
        @extend %trans;
        background: none;
    }
}


@media screen and (max-width: 550px){
    .button-logout{
        p{
            display: flex;
        }
    }
}