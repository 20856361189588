@import "../../../../Constant.scss";

.n-section{
    display: grid;
    width: 100%;
    justify-items: center;
    color: $color8;
    padding: 100px 0;
    min-height: 90vh;
    background-image: linear-gradient(to right top, #4ea6d6, #2699db, #008adf, #007ae0, #1e67dd);
    .n-section-title{
        display: grid;
        padding: 0 5%;
        max-width: 1400px;
        justify-items: start;
        .n-title-page-team{
            font-size: 35px;
            margin-block-start: 0em;
            margin-block-end: 0em;
            font-weight: 300;
            text-align: center;
            span{
                font-weight: 600;
                font-style: oblique;
            }
        }
    }
    .n-section-list-01{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        max-width: 1400px;
        padding: 30px 5%;
        .n-section-item-01{
            display: grid;
            grid-gap: 10px;
            .n-img-01{
                img{
                    border-radius: 40px;
                }
            }
            .n-section-contect{
                display: grid;
                grid-template-columns: auto 15%;
                .n-subtitle-page{
                    font-size: 16px;
                    font-style: oblique;
                    font-weight: 600;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    padding: 0;
                    text-align: start;
                    padding: 5px 15px;
                    border: 1px solid $color8;
                    border-radius: 20px;
                }
                .n-load-page{
                    padding: 5px 15px;
                    font-size: 14px;
                }
                .n-section-rrss{
                    display: grid;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    justify-items: center;
                    a{
                        color:$color1;
                        background: $color8;
                        padding: 5px;
                        display: grid;
                        border-radius: 50%;
                        border: 2px solid $color8;
                        @extend %trans;
                        font-size: 16px;
                        &:hover{
                            color:$color8;
                            background: none;
                            border: 2px solid $color8;
                            @extend %trans;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .n-section{
        .n-section-list-01{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 650px){
    .n-section{
        .n-section-list-01{
            display: grid;
            grid-template-columns:1fr;
            padding: 30px 40px;
            .n-section-item-01{
                display: grid;
                grid-gap: 10px;
                .n-img-01{
                    img{
                        border-radius: 30px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 350px){
    .n-section .n-section-title .n-title-page-team {
        font-size: 25px;
    }
    .n-section .n-section-list-01 .n-section-item-01 .n-section-contect .n-subtitle-page {
        font-size: 12px;
        text-align: start;
        padding: 5px 10px;
    }
    .n-section .n-section-list-01 .n-section-item-01 .n-section-contect .n-load-page {
        padding: 5px 10px;
        font-size: 10px;
    }
}