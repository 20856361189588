@import "../../../../Constant.scss";

.section-6-contect{
    z-index: 1;
    display: grid;
    min-height: 60vh;
    position: relative;
    margin-top: 130px;
    max-width: 1400px;
    margin-bottom: 150px;
    grid-gap: 50px;
    width: 95%;
    text-align: center;
    justify-items: center;
    .section-6-contect-img{
        max-width: 500px;
        z-index: 99;
    }
}