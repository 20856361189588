@import "../../../../Constant.scss";

.nav-menu-left{
    display: grid;
    grid-gap: 10px;
    max-height: 80vh;
    overflow-y: auto; 
    overflow-x: hidden;
    border-radius: 20px;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(226, 226, 226, 0.562);
        border-radius: 10px;
    }
    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
    .button-menu-left{
        display: flex;
        grid-gap: 10px;
        font-family: $font;
        font-size: $fsize;
        text-decoration: none;
        color:$color1;
        font-weight: 500;
        @extend %trans;
        background: none;
        padding: 15px;
        border-radius: 15px;
        border: none;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        color: $color8;
        svg{
            font-size: 20px;
            color: $color1;
        }
        p{
            display: flex;
            color: $color6;
        }
        &:hover{
            color: $color1;
            @extend %trans;
            background: $color8;
        }
    }
    .button-submenu{
        display: flex;
        grid-gap: 10px;
        font-family: $font;
        font-size: $fsize;
        text-decoration: none;
        color:$color1;
        font-weight: 500;
        @extend %trans;
        background: none;
        padding: 15px 16px;
        border-radius: 15px;
        border: none;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        color: $color8;
        width: 100%;
        svg{
            font-size: 20px;
            color: $color1;
        }
        p{
            display: flex;
            color: $color6;
        }
        .sub-icon-menu{
            display: grid;
            svg{
                font-size: 16px;
            }
        }
        .sub-menu{
            display: flex;
            align-items: center;
            grid-gap: 6px;
            width: 100%;
        }
        &:hover{
            color: $color1;
            @extend %trans;
            background: $color8;
        }
    }
    .submenu-left{
        position: relative;
        background: #fff;
        border-radius: 16px;
        .button-submenu-left{
            display: flex;
            grid-gap: 5px;
            font-family: $font;
            font-size: $fsize;
            text-decoration: none;
            color:$color1;
            font-weight: 500;
            @extend %trans;
            background: none;
            padding: 10px 16px;
            border-radius: 15px;
            border: none;
            font-weight: 500;
            cursor: pointer;
            align-items: center;
            color: $color8;
            svg{
                font-size: 16px;
                color: $color1;
            }
            p{
                font-size: 11px;
                display: flex;
                color: $color1;
            }
            &:hover{
                @extend %trans;
                svg{
                    font-size: 18px;
                    color: $color7;
                }
                p{
                    font-size: 11.5px;
                    display: flex;
                    color: $color7;
                }
            }
        }
        .button-submenu-active-pc{
            display: flex;
            grid-gap: 10px;
            font-family: $font;
            font-size: $fsize;
            text-decoration: none;
            color:$color1;
            font-weight: 500;
            @extend %trans;
            background: none;
            padding: 10px 20px;
            border-radius: 15px;
            border: none;
            font-weight: 500;
            cursor: pointer;
            align-items: center;
            color: $color8;
            svg{
                font-size: 18px;
                color: $color1;
            }
            p{
                font-size: 12px;
                display: flex;
                color: $color1;
            }
            &:hover{
                @extend %trans;
                svg{
                    font-size: 18px;
                    color: $color7;
                }
                p{
                    font-size: 13px;
                    display: flex;
                    color: $color7;
                }
            }
        }
    }
    .active-link {
        .button-menu-left {
            background-color: #fff; 
            color: #333; 
        }
    }
    .title-menu{
        text-align: center;
        font-size: 11px;
        color: $color1;
    }
}

@media screen and (max-width: 1400px){
    .nav-menu-left{
        .button-menu-left{
            p{
                display: none;
            }
            justify-content: center;
        }
        .button-submenu{
            p{
                display: none;
            }
            .sub-icon-menu{
                display: none;
            }
            .sub-menu{
                justify-content: center;
            }
        }
        .submenu-left{
            position: absolute;
            background: #fff;
            border-radius: 16px;
            z-index: 150;
            margin-left: 75px;
            margin-top: -50px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            .button-submenu-active-pc{
                display: none;
            }
        }
    }
}

@media screen and (max-width: 550px){
    .nav-menu-left{
        .button-menu-left{
            p{
                display: flex;
            }
        }
        .button-submenu{
            p{
                display: flex;
            }
        }
        .submenu-left{
            position: relative;
            background: #e4e4e4;
            border-radius: 16px;
            z-index: 150;
            margin-left: 0;
            margin-top: 0;
        }
    }
}