@import "../../../../Constant.scss";

.main-info-section{
    position: fixed;
    display: grid;
    min-width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 9999;
    max-width: 100vh;
    align-items: center;
    justify-items: center;
    overflow-y: auto; 
    overflow-x: hidden;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(169, 169, 169, 0.562);
        border-radius: 10px;
    }
    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
    .container-info-3{
        padding: 30px;
        .subtitle-page-3{
            text-align: center;
        }
    }
    .register-demo-info-3{
        display: grid;
        padding: 4% 10%;
    }
    .container-register-demo-info-3{
        display: grid;
        color:$color6;
        text-align: center;
        grid-gap: 10px;
        max-width: 700px;
        background: $color8;
        border-radius: 50px;
        margin: 20px 0;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
        .image-container-3 {
            width: 100%;
            height: 220px; // Ajusta la altura según tus necesidades
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px 50px 0px 0px;
            .section-3-card-img {
                width: 100%;
                height: 100%;
                object-fit: cover; // Recorta la imagen para que cubra el contenedor
                object-position: center; // Centra la imagen dentro del contenedor
            }
        }
    }
    .container-register-demo-info-3-title{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        grid-gap: 30px;
        padding: 10px 0;
        .container-register-demo-info-3-title-img{
            width: 40px;
            height: auto;
        }
    }
    .container-register-demo-info-3-grill{
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(2,1fr);
        padding: 20px 0;
        .container-register-demo-info-3-card{
            display: grid;
            grid-gap: 10px;
            justify-items: center;
            .container-register-demo-info-3-card-img{
                width: 40px;
                height: auto;
            }
        }
    }
    .form-info-3{
        display: grid;
        justify-content: center;
        .input-info-3{
            display: flex;
            padding: 8px 10px;
            border-radius: 16px;
            border:1px solid $color1;
            @extend %glass1;
            input{
                width: 95%;
                font-family: Poppins;
                font-size: 14px;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            .button-form-info-3{
                color: $color1;
                font-size: 16px;
                &:hover{
                    font-size: 20px;
                    margin: -2px;
                }
            }
        }
    }
}


@media screen and (max-width: 550px){
    .main-info-section{
        .container-register-demo-info-3{
            border-radius: 30px;
            .image-container-3 {
                height: 180px; // Ajusta la altura según tus necesidades
                border-radius: 30px 30px 0px 0px;
            }
        }
        .container-register-demo-info-3-title {
            grid-gap: 5px;
            padding: 5px 0;
        }
        .container-register-demo-info-3-grill {
            grid-gap: 10px;
            padding: 10px 0;
            grid-template-columns: 1fr;
        }
        .form-info-3{
            display: grid;
            justify-content: center;
            .input-info-3{
                border-radius: 14px;
                input{
                    font-size: 11px;
                }
                .button-form-info-3{
                    font-size: 14px;
                    &:hover{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}