@import "../../../../Constant.scss";



.section-4{
    display: grid;
    margin: 15vh 0px;
    width: 95%;
    grid-template-rows: 1fr;
    grid-gap: 40px;
    justify-items: center;
    max-width: 1400px;
    .section-4-contect{
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr;
        align-items: center;
        align-content: center;
        text-align: center;
    }
    .section-4-contect-card{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 5%;
        z-index: 5;
        justify-items: center;
        justify-content: center;
        .info-section-4-card{
            display: grid;
            justify-items: center;
            text-align: center;
            grid-gap: 20px;
            grid-template-rows: 200px 100px 30px 70px;
            .section-4-card-img-container{
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 30px;
                height: 200px;
                width: 300px;
                box-shadow: 0 4px 15px rgba(0, 0, 0, 0.205);
                .section-4-card-img{
                    width: 100%;
                    height: 100%; 
                    object-fit: cover;
                }
            }
            .title-card-4{
                height: 100px;
                display: grid;
                align-items: center;
            }
            .section-4-card-numb{
                color: $color8;
                width: 25px;
                height: 25px;
                background: $color1;
                border-radius: 50%;
                font-size: 16px;
                display: grid;
                align-items: center;
                justify-items: center;
            }
        }
    }
}

@media screen and (max-width: 1650px){
    .section-4 .section-4-contect-card .info-section-4-card .section-4-card-img-container {
        height: 150px;
        width: 200px;
    }
}

@media screen and (max-width: 1050px){
    .section-4 .section-4-contect-card {
        .info-section-4-card {
            grid-template-rows: 200px 150px 30px 70px;
        }
    }
}

@media screen and (max-width: 750px){
    .section-4 .section-4-contect-card {
        grid-template-columns: 1fr;
        grid-gap: 0px;
        .info-section-4-card {
            grid-template-rows: 150px 100px 30px 70px;
            grid-gap: 20px;
            margin: 20px 0;
        }
    }
}