@import "../../../Constant.scss";

.main-register{
    position: fixed;
    display: grid;
    min-width: 100%;
    min-height: 100vh;
    background: $color1;
    z-index: 999;
    justify-content: center;
    align-items: center;
}