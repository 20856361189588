@import "../../../../Constant.scss";

.dashboard-sec4{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    height: 100%;
    .huella-carbono-siigo {
        display: flex;    
        flex-direction: column;
        border-radius: 25px;
        align-items: center;
        background-image: url('https://firebasestorage.googleapis.com/v0/b/goshofi-c578e.appspot.com/o/brands%2Filustrasi-green-industri_ratio-16x9.jpg?alt=media&token=bc99f2f1-4caa-4441-aacd-d110d41d347c');
        background-size: cover; // Cubre completamente el div, ajustándose al tamaño
        background-position: center; // Centra la imagen de fondo
        @extend %glass1;
        justify-content: center;
        min-height: 190px;
        p {
            color: white; 
            font-size: 40px; 
            text-align: center;
            font-weight: 700;
            text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.692);
        }
        .p-contect{
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
    .counter-siigo{
        display: flex;    
        border-radius: 25px;
        align-items: center;
        @extend %glass1;
        justify-content: center;
        min-height: 190px;
        padding: 0px 15px;
        text-decoration: none;
        img{
            width: 50%;
            max-width: 150px;
            height: auto;
            background-image: linear-gradient(to right bottom, #2a9df4, #0badef, #35bbe6, #5fc6dc, #85d0d5);
            border-radius: 50%;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.212);
        }
        .text-title {
            color: #0470c2; 
            font-size: 50px; 
            text-align: center;
            font-weight: 800;
            margin: 15px;
        }
        .p-contect{
            font-size: 14px;
            margin: 10px;
            font-weight: 600;
            text-align: center;
            color: #0470c2; 
        }
        &:hover{
            opacity: 0.8;
        }
    }
}

@media screen and (max-width: 900px){
    .dashboard-sec4{
        grid-template-columns: 1fr;
        .huella-carbono-siigo {
            p {
                padding: 20px;
                font-size: 30px; 
                
            }
        }
    }
}

@media screen and (max-width: 750px){
    .dashboard-sec4{
        .huella-carbono-siigo {
            p {
                padding: 20px;
                font-size: 20px; 
            }
        }
    }
}

@media screen and (max-width: 550px){
    .dashboard-sec4{
        .huella-carbono-siigo {
            min-height: 150px;
            p {
                padding: 5px;
                font-size: 30px; 
            }
            .p-contect{
                font-size: 12px;
                margin-bottom: 0px;
            }
        }
        .counter-siigo{
            min-height: 150px;
            img{
                width: 50%;
                max-width: 100px;
                height: auto;
                background-image: linear-gradient(to right bottom, #2a9df4, #0badef, #35bbe6, #5fc6dc, #85d0d5);
                border-radius: 50%;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.212);
            }
            .text-title {
                font-size: 30px; 
                margin: 10px;
            }
            .p-contect{
                font-size: 10px;
                line-height: 9px;
                margin: 10px;
            }
        }
    }
}
