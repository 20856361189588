@import "../../../../Constant.scss";

.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-2N-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: auto 55%;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-2N-img  section-2N-title";
        margin: 100px 0px;
        max-width: 1400px;
        grid-gap: 30px;
        width: 90%;
    }
    .section-2N-title{
        grid-area: section-2N-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        z-index: 99;
        text-align: end;
        .title-page-nosotros{
            font-size: 50px;
            text-align: end;
            font-weight: 500;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $color1;
            span{
                font-style: oblique;
                font-weight: 700;
            }
        }
        .subtitle-page-nosotros{
            font-size: 28px;
            line-height: 60px;
            text-align: end;
            font-weight: 400;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $fontcolor;
            .span1 {
                border: 2px solid $color1;
                padding: 0 15px;
                border-radius: 30px;
                margin: 5px;
            }
            .span2 {
                color: $color1;
                font-style: oblique;
                font-weight: 600;
                margin: 5px;
            }
            .span3{
                color:$color8;
                background: $color1;
                padding: 0 15px;
                border-radius: 30px;
                margin: 5px;
                font-style: oblique;
                font-weight: 500;
            }
        }
        
        
    }
    .section-2N-img {
        grid-area: section-2N-img ;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 60vh;
        .img-0N {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
}


@media screen and (max-width: 1100px){
    .section-contect{
        .section-2N-contect-container{
            min-height: 30vh;
        }
        .section-2N-title{
            grid-gap: 30px;
            .title-page-nosotros{
                font-size: 35px;
            }
            .subtitle-page-nosotros{
                font-size: 18px;
                line-height: 35px;
            }
        }
        .section-0N-contect-container{
            min-height: 30vh;
        }
        .section-0N-title{
            grid-gap: 30px;
            .title-page-nosotros{
                font-size: 35px;
            }
            .subtitle-page-nosotros{
                font-size: 18px;
                line-height: 35px;
            }
        }
    }
}

@media screen and (max-width: 780px){
    .section-contect{
        .section-2N-contect-container{
            grid-template-columns: 1fr;
            min-height: 30vh;
            grid-template-areas: "section-2N-img"  "section-2N-title";
        }
        .section-2N-img .img-0N {
            border-radius: 30px;
        }
    }
}


@media screen and (max-width: 400px){
    .section-contect{
        .section-2N-title{
            grid-gap: 20px;
            .title-page-nosotros{
                font-size: 16px;
            }
            .subtitle-page-nosotros{
                font-size: 14px;
                line-height: 25px;
            }
        }
        .section-0N-title{
            grid-gap: 20px;
            .title-page-nosotros{
                font-size: 16px;
            }
            .subtitle-page-nosotros{
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}