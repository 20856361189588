@import "../../../../Constant.scss";

.title-timeline{
    font-size: 14px;
    color: $color7;
    font-family: $font;
}

.contect-timeline{
    font-size: 10px;
    color: $color7;
    font-family: $font;
}