@import "../../../../Constant.scss";

.home-admin{
    padding: 2vh;
    display: grid;
    grid-gap: 2vh;
    height: 83vh;
    grid-template-columns: 60% auto;
    align-items: start;
    grid-template-areas: "dashboard  dashboard-map";
    .dashboard-map{
        grid-area: dashboard-map;
        display: grid;
        width: 100%;
        height: 100%;
    }
    .dashboard{
        grid-area: dashboard;
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        height: 100%;
        .dashboard-sec1{
            display: grid;
            grid-area:dashboard-sec1;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            align-items: start;
        }
        .dashboard-sec2{
            display: grid;
            grid-area:dashboard-sec2;
        }
        .dashboard-sec3{
            height: 100%;
            @extend %glass1;
            border-radius: 30px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.103);
        }
        .title-sec{
            margin: 20px;
        }
    }
}

@media screen and (max-width: 1400px){
    .home-admin{
        grid-template-columns: 70% auto;
    }
}

@media screen and (max-height: 850px){
    .home-admin{
        margin: 2vh 2vh 0% 2vh;
        overflow-y: auto; 
        height: 81vh;
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
}


@media screen and (max-width: 1250px){
    .home-admin{
        grid-template-columns: 1fr;
        grid-template-areas:"dashboard-map"  "dashboard";
        margin: 2vh 2vh 0% 2vh;
        overflow-y: auto; 
        height: 81vh;
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .dashboard-map {
            min-height: 40vh;
        }
    }
}


@media screen and (max-width: 900px){
    .home-admin{
        .dashboard{
            .dashboard-sec1{
                grid-template-columns: 1fr;
                grid-gap: 10px;
            }
            .title-sec{
                margin: 10px;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .home-admin{
        grid-gap: 10px;
        grid-template-areas: "dashboard" "dashboard-map" ;
        .dashboard-map{
            display: none;
        }
        .dashboard{
            .title-sec{
                margin: 0 10px 10px 10px;
            }
        }
    }
}

