@import "../../../../Constant.scss";

.title-quiz{
    font-size: min(5vw, 45px);
    line-height: min(5vw, 45px) ;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}

.title2-quiz{
    font-size: min(4vw, 30px);
    line-height: min(4vw, 30px) ;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}

.windons{
    display: grid;
    padding: 2vh 2vh 0% 2vh;
    grid-template-rows: 7vh 78vh;
    .windons-quiz-init{
        width: 100%;
        height: 100%;
        display: grid;
        position: relative;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        .section-quiz-init{
            display: grid;
            grid-gap: 5%;
            margin-top: -5vh;
            grid-template-columns: repeat(2,1fr);
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            .title-shofi-loanding{
                font-size: min(2.5vw, 32px);
                line-height: min(2.7vw, 35px);
                text-align: center;
                color: $color7;
            }
            .contect-shofi-loanding{
                font-size: min(2vw, 22px);
                line-height: min(2vw, 25px);
                font-weight: 400;
                text-align: center;
                color: $color7;
            }
            .quiz-init-img{
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                align-items: center;
                img{
                    height: auto;
                    width: 80%;
                    max-width: 400px;
                    align-items: center;
                }
            }
            .quiz-init-contect{
                text-align: center;
                align-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                grid-gap: 5%;
                margin-bottom: 10%;
                .button-download{
                    border: none;
                    background: none;
                    font-size: 20px;
                    line-height: 0dvh;
                    font-family: $font;
                    color: $color1;
                    cursor: pointer;
                    font-weight: 500;
                    &:hover{
                        color: $color7;
                    }
                }
            }
        }
        .section-quiz-init2{
            display: grid;
            grid-gap: 50px;
            grid-template-columns: 1fr;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            .title-shofi-loanding{
                font-size: 2.2vw;
                line-height: 2.2vw;
                text-align: center;
                color: $color7;
            }
            .quiz-init-img{
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                align-items: center;
                img{
                    height: 80%;
                    width: auto;
                    max-width: 400px;
                    align-items: center;
                }
            }
            .quiz-init-contect{
                text-align: center;
                align-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                grid-gap: 50px;
                .button-download{
                    border: none;
                    background: none;
                    font-size: 20px;
                    line-height: 0dvh;
                    font-family: $font;
                    color: $color1;
                    cursor: pointer;
                    font-weight: 500;
                    &:hover{
                        color: $color7;
                    }
                }
            }
        }
    }
}

.windons-section-encuesta{
    display: grid;
    grid-template-rows: auto 45px;
    align-items: start;
    max-height: 75vh;
    .list-subcuestions{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .list-cuentions{
        display: grid;
        align-items: start;
        margin-top: 20px;
        overflow-y: auto;
        max-height: 60vh;
        .cuestions{
            display: grid;
            @extend %glass;
            padding: 20px;
            border-radius: 20px;
            margin: 10px 20px;
            grid-gap: 10px;
            .container-cuestions{
                display: grid;
                width: 97%;
                grid-template-columns: auto 45px;
                p{
                    font-size: 12px;
                    margin-left: 10px;
                    color: $color7;
                    font-weight: 800;
                    margin-bottom: 5px;
                }
                input{
                    width: 94%;
                    font-family: Poppins;
                    font-size: 16px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .container-seleccion{
                display: grid;
                grid-gap: 10px;
                align-items: end;
                grid-template-columns: repeat(4, 1fr);
                p{
                    font-size: 10px;
                    margin-left: 10px;
                    color: $color7;
                    margin-bottom: 5px;
                }
                .cuestions-opcion{
                    display: flex;
                    grid-gap: 10px;
                    input{
                        width: 90%;
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 10px 10px;
                        border-radius: 12px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button{
                        background: none;
                        border: none;
                    }
                    .div-input-radio{
                        display: grid;
                        justify-items: center;
                        justify-content: center;
                        .input-radio{
                            background: none;
                            margin-left: 10px;
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
                .button-plus{
                    color: $color11;
                    background: #66e21e;
                    border: #66e21e solid 2px;
                    padding: 10px 15px;
                    text-decoration: none;
                    border-radius: 50%;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    grid-gap: 10px;
                    font-size: 14px;
                    @extend %trans;
                    justify-content: center;
                    font-family: $font;
                    cursor: pointer;
                    height: 50px;
                    width: 50px;
                    svg{
                        font-size: 22px;
                    }
                    &:hover{
                        color: #8ef055;
                        background: none;
                        @extend %trans;
                        svg{
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .subcuestions{
            display: grid;
            @extend %glass1;
            padding: 20px;
            border-radius: 20px;
            margin: 10px 20px;
            grid-gap: 10px;
            .container-subcuestions{
                display: grid;
                width: 97%;
                grid-template-columns: auto 45px;
                p{
                    font-size: 10px;
                    margin-left: 10px;
                    color: $color7;
                    margin-bottom: 5px;
                }
                input{
                    width: 94%;
                    font-family: Poppins;
                    font-size: 16px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .container-subseleccion{
                display: grid;
                grid-gap: 10px;
                align-items: end;
                grid-template-columns: repeat(4, 1fr);
                p{
                    font-size: 10px;
                    margin-left: 10px;
                    color: $color7;
                    margin-bottom: 5px;
                }
                .cuestions-opcion{
                    display: flex;
                    grid-gap: 10px;
                    input{
                        width: 90%;
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 10px 10px;
                        border-radius: 12px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button{
                        background: none;
                        border: none;
                    }
                }
                .button-plus{
                    color: $color11;
                    background: #66e21e;
                    border: #66e21e solid 2px;
                    padding: 10px 15px;
                    text-decoration: none;
                    border-radius: 50%;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    grid-gap: 10px;
                    font-size: 14px;
                    @extend %trans;
                    justify-content: center;
                    font-family: $font;
                    cursor: pointer;
                    height: 50px;
                    width: 50px;
                    svg{
                        font-size: 22px;
                    }
                    &:hover{
                        color: #8ef055;
                        background: none;
                        @extend %trans;
                        svg{
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .container-button{
            display: flex;
            justify-content: flex-end;
            margin: 20px;

        }
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
    .botton-section{
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1600px){
    .windons-section-encuesta .list-cuentions .cuestions .container-seleccion{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px){
    .windons-section-encuesta .list-cuentions .cuestions .container-seleccion{
        grid-template-columns: 1fr;
    }
    .windons-section-encuesta .list-cuentions .cuestions {
        padding: 10px;
        border-radius: 16px;
        margin: 10px;
        grid-gap: 5px;
    }
    .windons-section-encuesta .list-cuentions .cuestions .container-seleccion p {
        font-size: 9px;
        margin-left: 10px;
        margin-bottom: 0px;
    }
    .windons-section-encuesta .list-cuentions .cuestions .container-seleccion .cuestions-opcion input{
        font-size: 12px;
        padding: 8px;
        border-radius: 10px;
    }
    .windons-section-encuesta .list-cuentions .cuestions .container-cuestions input {
        width: 100%;
        font-size: 14px;
        padding: 10px;
        border-radius: 12px;
    }
}



.delete-button{
    color: $color11;
    background: $color10;
    border: $color10 solid 2px;
    padding: 15px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: flex-start;
    font-family: $font;
    cursor: pointer;
    height: 50px;
    width: 150px;        
    svg{
        font-size: 22px;
    }
    &:hover{
        color: $color10;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}

.delete-subbutton{
    color: $color11;
    background: $color12;
    border: $color12 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 12px;
    @extend %trans;
    justify-content: flex-start;
    font-family: $font;
    cursor: pointer;
    height: 40px;
    width: 120px;        
    svg{
        font-size: 18px;
    }
    &:hover{
        color: $color12;
        background: none;
        @extend %trans;
        svg{
            font-size: 20px;
        }
    }
}

.container-input-bottom{
    .input-bottom{
        position: fixed;
        width: 91%;
        z-index: 99;
        font-family: Poppins;
        font-size: 12px;
        padding: 8px 12px;
        border-radius: 16px;
        background: $color8;
        @extend %glass1;
        overflow-y: auto;
        max-height: 100px;
        min-height: 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.24);
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 5px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 5px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .display-button{
            display: flex;
            flex-wrap: wrap;
            gap: 2px; 
        }
    }
    .input-select{
        position: relative;
        width: 96%;
        font-family: Poppins;
        font-size: 12px;
        padding: 10px 2%;
        border-radius: 16px;
        @extend %glass1;
        overflow-y: auto;
        max-height: 100px;
        min-height: 20px;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 5px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 5px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .display-button{
            display: flex;
            flex-wrap: wrap;
            gap: 2px; 
        }
    }
    button{
        font-size: 12px;
        text-align: start;
        font-family: $font;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        border-radius: 10px;
        margin: 2px;
    }
    .opcion-button{
        border: 1px solid $color13;
        color: $color13;
        background: #b0ff8252;
    }
    .opcion-delete{
        border: 1px solid #ff9e9e;
        color: #ff9e9e;
        background:rgba(255, 130, 130, 0.137)
    }
    .opcion-opcion{
        border: 1px solid #ecdc47;
        color: #ecdc47;
        background:rgba(255, 239, 165, 0.137)
    }
}

.button-load{
    color: $color11;
    background: $color2;
    border: $color2 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: $color2;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}

.content-input input, .content-select select{
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.content-input input{
	visibility: hidden;
	position: absolute;
	right: 0;
}

.content-input{
	position: relative;
    margin-top: 30px;
	margin-bottom: 30px;
	padding:5px 0 5px 60px;
	display: block;
}

.content-input input + i{
    background: #f0f0f0;
    border:2px solid rgba(0,0,0,0.2);
    position: absolute; 
    left: 0;
    top: 0;
}

.content-input input[type=checkbox ] + i{
	width: 52px;
	height: 30px;
	border-radius: 15px;
}

.content-input{
	position: relative;
	margin-bottom: 30px;
	padding:5px 0 5px 60px;
	display: block;
}

.content-input input + i{
    background: #f0f0f0;
    border:2px solid rgba(0,0,0,0.2);
    position: absolute; 
    left: 0;
    top: 0;
}

.content-input input[type=checkbox ] + i{
	width: 48px;
	height: 25px;
	border-radius: 15px;
}

.content-input input[type=checkbox] + i:before{
	content: '';
	width: 25px;
	height: 25px;
	background: #fff;
	border-radius: 50%;
	position: absolute;
	z-index: 1;
	left: 0px;
	top: 0px;
	-webkit-box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
	box-shadow: 3px 0 3px 0 rgba(0,0,0,0.2);
}

.content-input input[type=checkbox]:checked + i:before{
	left: 22px;
	-webkit-box-shadow: -3px 0 3px 0 rgba(0,0,0,0.2);
	box-shadow: 3px 0 -3px 0 rgba(0,0,0,0.2);
}

.content-input input[type=checkbox]:checked + i{
    background:$color13;
}

.content-input input[type=checkbox] + i:after{
	content: 'ON';
	position: absolute;
	font-size: 10px;
	color: rgba(255, 255, 255, 0.89);
	top: 5px;
	left: 4px;
	opacity: 0 /* Ocultamos este elemento */;
	transition: all 0.25s ease 0.25s;
}

/* Cuando esté checkeado cambiamos la opacidad a 1 y lo mostramos */
.content-input input[type=checkbox]:checked + i:after{
    opacity: 1;
}

.wimdons-quiz-contador-result{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.wimdons-quiz-contador{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    text-align: center;
    background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
    .card-quiz-contador{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.822);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
        backdrop-filter: blur(14.2px);
        -webkit-backdrop-filter: blur(14.2px);
        h2{
            font-size: min(5vw, 60px);
            line-height: min(5vw, 60px) ;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 10px 0;
            padding: 0;
        }
        h1{
            font-size: 25px;
            line-height: 25px;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
        }
        p{
            font-size: 14px;
            line-height: 14px;
        }
    }
}

.dates-dashboard{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    .grad{
        position: relative;
    }
    .result{
        position: absolute;
        color: $color1;
    }
}

.wimdons-quiz-questions{
    display: grid;
    grid-gap: 40px;
    justify-items: center;
    justify-content: center;
    .wimdons-quiz-questions-gallery{
        display: flex;
        justify-content: center;
        .wimdons-quiz-questions-img{
            width: 60%;
            max-height: 600px;
            margin: 2%;
            border-radius: 30px;
        }
    }
    .title-result{
        font-size: 30px;
        line-height: 30px;
        color: $color1;
        span{
            font-weight: 800;
        }
    }
}

.button-result{
    color: $color11;
    background: $color1;
    border: $color1 solid 2px;
    padding: 10px 30px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 20px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
        color: #fff;
    }
    &:hover{
        color: $color1;
        background: none;
        border: $color1 solid 2px;
        @extend %trans;
        svg{
            font-size: 24px;
            color: $color1;
        }
    }
}

.wimdons-quiz{
    display: grid;
    justify-items: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(to right top, #e4ebf3, #eff6ff, #e2ecf7, #f0f7ff, #f3f8fd);
    .wimdons-quiz-questions{
        width: 90vw;
        max-width: 1000px;
        display: grid;
        grid-gap: 30px;
        p{
            font-weight: 600;
            text-align: center;
            color: $color7;
        }
        .container-button-quiz{
            display: flex;
            grid-gap: 20px;
            justify-content: center;
            align-content: center;
            align-items: center;
            button{
                cursor: pointer;
                padding: 30px 20px;
                border: 2px solid $color2;
                border-radius: 50px;
                min-width: 200px;
                background: $color2;
                @extend %trans;
                .contect-quiz2{
                    font-size:30px;
                    color: $color11;
                }
                .contect-quiz{
                    font-size:18px;
                    line-height: 18px;
                    color: $color11;
                }
                &:hover{
                    @extend %trans;
                    background: $color3;
                    border: 2px solid $color3;
                }
            }
        }
        .block-button-quiz{
            display: grid;
            grid-gap: 20px;
            justify-content: center;
            align-content: center;
            align-items: center;
            button{
                cursor: pointer;
                padding: 10px 20px;
                border: 2px solid $color2;
                border-radius: 50px;
                min-width: 200px;
                background: $color2;
                @extend %trans;
                .contect-quiz2{
                    font-size:20px;
                    color: $color11;
                }
                .contect-quiz{
                    font-size:16px;
                    line-height: 16px;
                    color: $color11;
                }
                &:hover{
                    @extend %trans;
                    background: $color3;
                    border: 2px solid $color3;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .windons .windons-quiz-init{
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .section-quiz-init{
            grid-gap: 10px;
            grid-template-columns: 1fr;
        }
        .section-quiz-init .quiz-init-contect {
            grid-gap: 20px;
        }
    }
}

@media screen and (max-width: 900px){
    .windons{
        .windons-quiz-init{
            // padding: 10vw 0 0 0 ;
            // max-height: 64vh;
            .section-quiz-init .title-shofi-loanding {
                font-size: 14px;
                line-height: 15px;
            }
            .section-quiz-init .contect-shofi-loanding{
                font-size: 12px;
                line-height: 13px;
            }
            .section-quiz-init .quiz-init-img {
                height: auto;
            }
            .section-quiz-init .quiz-init-contect{
                padding: 0 5px;
            }
        }
    }
    .wimdons-quiz-questions{
        .wimdons-quiz-questions-gallery{
            .wimdons-quiz-questions-img{
                width: 80%;
            }
        }
    }
    .wimdons-quiz{
        .wimdons-quiz-questions{
            .container-button-quiz{
                button{
                    padding: 10px 20px;
                    border-radius: 20px;
                    min-width: 80px;
                    .contect-quiz2{
                        font-size:20px;
                    }
                    .contect-quiz{
                        font-size:16px;
                        line-height: 16px;
                    }
                }
            }
            .block-button-quiz{
                button{
                    cursor: pointer;
                    padding: 10px 20px;
                    border: 2px solid $color2;
                    border-radius: 20px;
                    min-width: 80px;
                    @extend %trans;
                    .contect-quiz2{
                        font-size:20px;
                    }
                    .contect-quiz{
                        font-size:16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}


.windons-admin-send{
    padding: 2vh 2vh 0% 2vh;
    display: grid;
    grid-template-rows: 7vh 78vh;
    .img-item-product{
        width: 70px;
        height: 70px;
        border-radius: 50%;
    }
    .windons-title-send{
        display: grid;
        min-height: 50px;
        grid-template-columns: auto 150px;
        align-items: start;
        p{
            font-size: 12px;
            margin-left: 10px;
            color: $color7;
            margin-bottom: 5px;
        }
        .windons-title-info{
            display: flex;
            .input-date{
                display: grid;
                width: 90%;
                padding: 0 5% 0 0;
                label{
                    padding: 0 12px;
                    font-size: 10px;
                    color:$color7;
                }
                input{
                    width: 95%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 15px;
                    border-radius: 16px;
                    border: none;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 8px 10px;
                    border-radius: 12px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
        }
    }
    .windons-section-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 20px;
        width: 100%;
        grid-template-areas: "seleccione-group  seleccione-employe";
        .section-info{
            max-height: 77vh;
            .search-input{
                font-size: 12px;
                color:$color7;
                @extend %glass1;
                padding: 10px 15px;
                border-radius: 16px;
                border: none;
                font-family: "Poppins", sans-serif;
                margin-bottom: 10px;
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            p{
                font-size: 12px;
            }
            .list-info{
                display: flex;
                flex-direction: column;
                max-height: 68vh;
                overflow-y: auto;
                grid-gap: 10px;
                margin-bottom: 20px;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-produc{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    cursor: pointer;
                    background: $color11;
                    border-radius:20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            padding: 10px;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
                .item-kits-as{
                    display: grid;
                    grid-template-columns: 65px auto 40px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px;
                    background: $color11;
                    border-radius: 20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 14px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 10px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 30px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 25px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .contect-seller-item-kits{
                        display: grid;
                        text-align: center;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 33px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    .img-item-product{
                        cursor: pointer;
                        border: none;
                        .avatar-letter{
                            font-size: 20px;
                            font-weight: 300;
                            margin-block-start: 0em;
                            margin-block-end: 0em;
                            margin-inline-start: 0px;
                            margin-inline-end: 0px;
                            line-height: 0px;
                            background: $color1;
                            color: $color8;
                            padding: 10px;
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            display: grid;
                            justify-content: center;
                            align-items: center;
                            justify-items: center;
                            align-content: center;
                        }
                        .avatar-img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%; /* Esto crea un círculo */
                            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                            position: relative; /* Permite centrar vertical y horizontalmente */
                            .avatar-img img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                                object-position: center center; /* Centra la imagen */
                            }
                        }
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
        .section-info1 {
            @extend %glass;
            padding: 5%;
            border-radius: 40px;
            margin-bottom: 2vh;
            h2 {
                margin: 20px 15px;
            }
            .input-date {
                display: grid;
                width: 100%;
                label {
                    padding: 0 12px;
                    font-size: 10px;
                    color: $color7;
                }
                input,
                select,
                textarea,
                option { // Añadido option aquí también
                    width: 100%; // Asegurar que todos tomen el 100% del ancho
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 15px;
                    border-radius: 14px;
                    @extend %glass1;
                    border: none;
                    box-sizing: border-box; 
                    &:focus, &:active {
                        outline: none;
                    }
                }
                textarea {
                    resize: vertical;
                    box-sizing: border-box;
                    min-height: 15vh;
                    margin-bottom: 10px;
                }
            }
        }
        .seleccione-group{
            grid-area: seleccione-group;
        }
        .seleccione-employe{
            grid-area: seleccione-employe;
        }
    }
    .windons-section-group{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        height: 100%;
        grid-gap: 30px;
        width: 100%;
        .section-info-group{
            max-height: 100%;
            margin-bottom: 5%;
            .list-info-group{
                margin-top: 1vh;
                display: flex;
                flex-direction: column;
                max-height: 70vh;
                overflow-y: auto;
                grid-gap: 10px;
                p{
                    font-size: 12px;
                }
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
                .item-kits-group{
                    text-decoration: none;
                    display: grid;
                    grid-template-columns: 75px auto 150px 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    background: $color11;
                    border-radius: 20px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .contect-seller-item-kits{
                        text-align: center;
                        display: grid;
                    }
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    .search{
                        color: $color11;
                        background: $color1;
                        font-size: 20px;
                        padding: 10px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    &:hover{
                        background: $color1;
                        .name{
                            color: $color11;
                            @extend %trans;
                        }
                        p{
                            color: $color11;
                            @extend %trans;
                        }
                        svg{
                            color: $color1;
                            background: $color11;
                            font-size: 30px;
                            @extend %trans;
                        }
                    }
                }
                .item-produc-selec{
                    display: grid;
                    grid-template-columns: 75px auto 50px;
                    border: none;
                    padding: 10px;
                    margin: 0 10px 0 0;
                    text-align: center;
                    background: $color11;
                    border-radius: 40px;
                    align-content: center;
                    align-items: center;
                    font-family: $font;
                    .name{
                        font-weight: 600;
                        font-size: 16px;
                        color: $color6;
                        @extend %trans;
                    }
                    p{
                        font-size: 12px;
                        color: $color7;
                        @extend %trans;
                    }
                    svg{
                        color: $color11;
                        background: $color10;
                        font-size: 35px;
                        border-radius: 50%;
                        @extend %trans;
                        cursor: pointer;
                    }
                    button {
                        background: none;
                        border: none;
                    }
                    &:hover{
                        background: $color9;
                        svg{
                            font-size: 40px;
                            @extend %trans;
                        }
                    }
                }
            }
        }
    }
    .section-title{
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        padding: 10px 0;
    }
    .container-button{
        display: flex;
        margin-top: 20px;
        justify-content: space-between;
    }
    input{
        width: 70%;
        font-family: Poppins;
        font-size: 12px;
        padding: 15px;
        border-radius: 18px;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .button-eidicion{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 8px;
        text-decoration: none;
        border-radius: 14px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 5px;
        font-size: 12px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        cursor: pointer;
        svg{
            font-size: 18px;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-height: 850px){
   .windons-admin-send{
        margin: 2vh 2vh 0% 2vh;
        overflow-y: auto; 
        height: 81vh;
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 0px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            border-radius: 0px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}


@media screen and (max-width: 1100px){
    .windons-admin-send{
        padding: 2vh 2vh 0% 1vh;
        .windons-section-info {
            grid-template-columns:1fr ;
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas: "seleccione-group" "seleccione-employe";
            max-height: 75vh;
            overflow-y: auto;
            padding: 10px 10px 0 10px;
            width: 98%;
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
            &::-webkit-scrollbar {
            width: 8px;
            }
            .section-info1{
                padding: 20px;
                border-radius: 20px;
            }
        }
        .windons-title-send {
            display: grid;
            grid-template-columns: auto;
            justify-content: end;
            .title-info{
                display: none;
            }
        }
    }
    .windons-admin-send .windons-section-info .section-info .list-info {
        max-height: 50vh;
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 0px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 0px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}

@media screen and (max-height:750px){
    .windons-admin-send{
        max-height: 90vh;
        overflow-y: auto;
        grid-template-rows: 15vh 78vh;
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 0px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            border-radius: 0px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
}
