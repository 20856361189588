@import "../../../../Constant.scss";

.unassigned{
    font-size: 11px;
    padding: 5px;
    background: $color7;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.verification{
    font-size: 11px;
    padding: 5px;
    background: $color4;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.packing{
    font-size: 11px;
    padding: 5px;
    background: $color3;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.preparation{
    font-size: 11px;
    padding: 5px;
    background: $color3;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}


.pickup{
    font-size: 11px;
    padding: 5px;
    background: $color2;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.route{
    font-size: 11px;
    padding: 5px;
    background: $color1;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.delivered{
    font-size: 11px;
    padding: 5px;
    background: $color13;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.cancelled{
    font-size: 11px;
    padding: 5px;
    background: $color10;
    color: $color8;
    border-radius: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 5px;
}

.loanding{
    font-size: 11px;
    color: $color7;
    border-radius: 12px;
}