@import "../../../../Constant.scss";

.empty-form-quiz{
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    .img-shofi-loanding{
        width: 80%;
        max-width: 300px;
    }
    .title-shofi-loanding{
        font-size: 2.2vw;
        line-height: 2.2vw;
        text-align: center;
        color: $color7;
    }
}

.title-form-send{
    p{
        font-size: 12px;
    }
    margin-bottom: 10px;
}