@import "../../../../Constant.scss";

.footer{
    display: grid;
    background-image: linear-gradient(to right bottom, #1551b7, #1755bf, #1959c7, #1a5dcf, #1c61d7, #1c60d6, #1b60d5, #1b5fd4, #1859c9, #1553bf, #124eb4, #0e48aa);
    width: 100%;
    justify-items: center;
    .container-footer{
        max-width: 1400px;
        display: grid;
        padding: 50px 5%;
        grid-gap: 5%;
        width: 90%;
        grid-template-columns: 35% auto 400px;
        .footer-rrss{
            .nav-footer{
                display: none;
            }
            .title-rrss{
                color: $color8;
                font-size: 16px;
                line-height:16px;
                padding: 0;
                margin: 0;
                margin-bottom: 20px;
                border: none;
                background: none;
                font-family: $font;
                cursor: pointer;
            }
            .title-map{
                color: $color8;
                font-size: max(1.5vw, 18px);
                padding: 0;
                margin: 0;
            }
            .button-email{
                display: flex;
                grid-gap: 10px;
                margin: 20px 0;
                width: 220px;
                background: $color8;
                border: 2px solid $color8;
                border-radius: 16px;
                padding: 10px 10px;
                align-items: center;
                font-family: Poppins;
                font-weight: 600;
                color: $color1;
                font-size: 14px;
                cursor: pointer;
                text-decoration: none;
                svg{
                    font-size: 14px;
                }
                &:hover{
                    border: 2px solid $color8;
                    color: $color8;
                    background: none;
                    @extend %trans;
                }
            }
            .redes-menu{
                display: flex;
                grid-gap: 10px;
                margin-bottom: 40px;
                a{
                    color:$color1;
                    background: $color8;
                    padding: 10px;
                    display: grid;
                    border-radius: 50%;
                    border: 2px solid $color8;
                    @extend %trans;
                    &:hover{
                        color:$color8;
                        background: none;
                        border: 2px solid $color8;
                        @extend %trans;
                    }
                }
            }
        }
        .footer-brand{
            display: flex;
            grid-gap: 20px;
            flex-direction: column;
            .brand{
                max-width: 120px;
            }
            .title-brand-footer{
                color: $color8;
                font-size: 24px;
                text-align: left;
                line-height: 26px;
            }
        }
        .site-map{
            display: grid;
            grid-gap: 15px;
            .button-footer{
                color: $color8;
                text-decoration: none;
                font-size: 14px;
                @extend %trans;
                text-align: start;
                font-family: $font;
                background: none;
                border: none;
                cursor: pointer;
                &:hover{
                    font-size: 15px;
                    @extend %trans;
                }
            }
        }
    }
    .copy-footer{
        display: grid;
        color: $color8;
        grid-template-columns: 1fr;
        padding: 10x 50px;
        justify-items: center;
        align-items: center;
        .copy{
            font-size: 11px;
            margin: 5px;
            padding: 5px;
        }
    }
}



@media screen and (max-width: 1450px){
    .footer{
        .container-footer{
            grid-gap: 20px;
            grid-template-columns: 300px auto 200px;
        }
    }
}

@media screen and (max-width: 1450px){
    .footer{
        .container-footer{
            grid-gap: 20px;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            .footer-brand{
                width: 100%;
                .title-brand-footer{
                    font-size:20px;
                    line-height: 20px;
                }
            }
            .form-footer{
                display: none;
            }
            .footer-rrss{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .nav-footer{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
            .site-map {
                display: grid;
                grid-gap: 12px;
                justify-items: end;
                .button-footer{
                    font-size: 12px;
                    &:hover{
                        font-size: 11px;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 550px){
    .footer{
        .container-footer{
            padding: 50px 5% 30px 5%;
            grid-template-columns:1fr;
            justify-items: start;
            .footer-rrss{
                align-items: flex-start;
                .nav-footer {
                    align-items: flex-start;
                }
            }
            .site-map {
                grid-gap: 10px;
                justify-items: start;
            }
        }
    }
}

