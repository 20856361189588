@import "../../../../Constant.scss";

.section-3{
    min-height: 70vh;
    background-image: linear-gradient(to right bottom, #4ea6d6, #54b1d7, #61bcd6, #72c6d5, #85d0d5);
    display: grid;
    width: 100%;
    justify-items: center;
    justify-content: center;
    .section-3-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: 60% 40%;
        min-height: 60vh;
        position: relative;
        max-width: 1400px;
        width: 90%;
        .section-3-contect-container-left{
            display: grid;
            grid-gap: 30px;
            margin: 100px 0;
            .section-3-contect-container-left-card{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 20px;
                .info-section-3-card{
                    display: grid;
                    padding: 20px 30px;
                    @extend %glass;
                    grid-gap: 20px;
                    border-radius: 40px;
                    text-align: justify;
                    .section-3-card-img{
                        max-width: 40px;
                    }
                }
            }
        }
        .section-3-contect-container-right{
            display: grid;
            align-items: end;
            img{
                width: 110%;
                margin-left: -10%;
                z-index: 99;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .section-3{
        .section-3-contect-container{
            z-index: 1;
            display: grid;
            grid-template-columns:1fr;
            .section-3-contect-container-right{
                display: none;
            }
            .section-3-contect-container-left .section-3-contect-container-left-card .info-section-3-card {
                padding: 20px;
                grid-gap: 10px;
                border-radius: 30px;
            }
        }
    }
}

@media screen and (max-width: 550px){
    .section-3 .section-3-contect-container .section-3-contect-container-left .section-3-contect-container-left-card {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    } 
}