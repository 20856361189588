@import "../../../../Constant.scss";

.space-bottom{
    margin-top: 10px;
}

.contect-carga-datos{
    display: grid;
    text-align: center;
    justify-items: center;
    justify-content: center;
    grid-gap: 10vh;
}

.card-quiz-contador{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.822);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
    backdrop-filter: blur(14.2px);
    -webkit-backdrop-filter: blur(14.2px);
    h2{
        font-size: min(5vw, 60px);
        line-height: min(5vw, 60px) ;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin: 10px 0;
        padding: 0;
    }
    h1{
        font-size: 25px;
        line-height: 25px;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin: 0;
        padding: 0;
    }
    p{
        font-size: 14px;
        line-height: 14px;
    }
}

@media screen and (max-width: 500px){
    .card-quiz-contador{
        display: flex;
        flex-direction: column;
        text-align: center;
        h2{
            font-size: 40px;
            margin: 20px 0;
        }
    }
}

.galery-photo-result{
    display:flex;
    align-items: center;
    border-radius: 30px;
    padding: 20px;
    grid-gap: 5%;
    background: rgba(255, 255, 255, 0.822);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
    backdrop-filter: blur(14.2px);
    -webkit-backdrop-filter: blur(14.2px);
    .input-img{
        max-width: 20%;
        img{
            border-radius: 20px;
            max-width: 100%;
        }
    }
}

.date-user-prove-quiz-init{
    margin-top: 20px; /* Ajustado de 70vh a un valor más razonable */
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.822);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
    backdrop-filter: blur(14.2px);
    -webkit-backdrop-filter: blur(14.2px);
    display: grid;
    width: 90%;
    padding: 40px;
    grid-template-columns: 35% auto;
    text-align: start;
    justify-items: center;
    align-content: center;
    .quiz-init-img{
        display: grid;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;
        text-align: center;
        img{
            width: 300px;
            height: auto;
        }
    }
    .quiz-init-contect{
        display: grid;
        text-align: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        align-content: center;
        padding: 5%;
        .title-shofi{
            font-size: min(4vw, 40px);
            line-height: min(4vw, 40px) ;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
        }
    }
}

.windons-quiz-result{
    padding: 40px 40px 0 40px;
    display: grid;
    grid-gap: 10px;
    min-height: 80vh;
    .windons-title-result{
        display: flex;
        justify-content: space-between;
        .title-aswer{
            font-size: min(4vw, 30px);
            line-height: min(4.3vw, 35px);
            font-weight: 800;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
        }
    } 
    .wimdons-quiz-contador-result-answer{
        justify-items: center;
        align-items: center;
        align-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        grid-gap: 10px;
        padding-right: 10px; /* Añadido para compensar el ancho del scrollbar */
        max-height: 75vh;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        .cabezera-quiz-contador{
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(2,1fr);
            width: 95%;
            padding: 40px 2% 0px 2%;
            .card-quiz-contador{
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 30px;
                padding: 20px;
                background: rgba(255, 255, 255, 0.822);
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
                backdrop-filter: blur(14.2px);
                -webkit-backdrop-filter: blur(14.2px);
                h2{
                    font-size: min(5vw, 60px);
                    line-height: min(5vw, 60px) ;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    margin: 10px 0;
                    padding: 0;
                }
                h1{
                    font-size: 25px;
                    line-height: 25px;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    margin: 0;
                    padding: 0;
                }
                p{
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
        .date-user-prove{
            margin-top: 20px; /* Ajustado de 70vh a un valor más razonable */
            border-radius: 30px;
            background: rgba(255, 255, 255, 0.822);
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
            backdrop-filter: blur(14.2px);
            -webkit-backdrop-filter: blur(14.2px);
            display: grid;
            width: 90%;
            padding: 40px 2%;
            grid-template-columns: repeat(2, 1fr);
            text-align: start;
            justify-items: center;
            .date-input{
                width: 95%;
                display: grid;
                p{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none;
                }
                label{
                    padding: 0 15px;
                    font-size: 12px;
                    color: #5c5c5c;
                }
            }
        }
        .dates-dashboard-result-quiz{
            display: grid;
            justify-items: center;
            align-items: center;
            justify-content: center;
            align-content: center;
            position: relative;
            margin-top: 40px;
            .grad{
                position: relative;
            }
            .title-result{
                position: absolute;
                color: $color1;
                span{
                    font-weight: 800;
                }
            }
        }
    }
}

.cabezera-quiz-contador-cabe{
    display: grid;
    grid-gap: 40px;
    width: 95%;
    padding: 40px 2% 0px 2%;
    .card-quiz-contador{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.822);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
        backdrop-filter: blur(14.2px);
        -webkit-backdrop-filter: blur(14.2px);
        h1{
            font-size: 25px;
            line-height: 25px;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
        }
        h2{
            font-size: min(4vw, 50px);
            line-height: min(4vw, 50px) ;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
        }
    }
}

.section-icon-result-quiz{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
    padding: 20px 40px;
    position:static;
    .counter-result{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        
        .notification-counter{
            padding: 5px;
            border-radius: 50%;
            background: #ff4444;
            min-height: 30px;
            min-width: 30px;
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            h2{
                font-size: 18px;
                color: #ffffff;
            }
        }
    }
    .result-pending{
        padding: 30px;
        background: rgba(255, 246, 246, 0.822);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
        backdrop-filter: blur(14.2px);
        -webkit-backdrop-filter: blur(14.2px);
        border-radius: 30px;
        h2{
            font-size: min(3vw, 18px);
            line-height: min(3.1vw, 20px);
            font-weight: 800;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: #ff4444;
        }
        p{
            font-size: 12px;
            color: #ff4444;
        }
        svg{
            font-size: 40px;
            color: #ff4444;
        }
    }
    .result-positive{
        padding: 30px;
        background: rgba(240, 246, 253, 0.822);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.295);
        backdrop-filter: blur(14.2px);
        -webkit-backdrop-filter: blur(14.2px);
        border-radius: 30px;
        h2{
            font-size: min(3vw, 18px);
            line-height: min(3.1vw, 20px);
            font-weight: 800;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: #1E67E2;
        }
        p{
            font-size: 12px;
            color: #1E67E2;
        }
        svg{
            font-size: 40px;
            color: #1E67E2;
        }
    }
}

@media screen and (max-width: 1500px){
    .section-icon-result-quiz{
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (max-width: 1450px){
    .section-icon-result-quiz{
        grid-template-columns: repeat(4, 1fr);
        padding: 20px;
    }
    .windons-quiz-result .wimdons-quiz-contador-result-answer .cabezera-quiz-contador {
        grid-template-columns: 1fr;
        padding: 20px 2% 0px 2%;
        grid-gap: 10px;
    }
}

@media screen and (max-width: 1450px){
    .section-icon-result-quiz{
        grid-template-columns: repeat(3, 1fr);
        padding: 15px;
        grid-gap: 10px;
    }
}

@media screen and (max-width: 780px){
    .section-icon-result-quiz{
        grid-template-columns: repeat(2, 1fr);
    }
    .section-icon-result-quiz .result-positive {
        padding: 20px;
        border-radius: 20px;
    }
    .section-icon-result-quiz .result-pending {
        padding: 20px;
        border-radius: 20px;
    }
    .windons-quiz-result .wimdons-quiz-contador-result-answer .cabezera-quiz-contador .card-quiz-contador {
        flex-direction: column;
    }
    .windons-quiz-result .wimdons-quiz-contador-result-answer .cabezera-quiz-contador .card-quiz-contador{
        h1 {
            font-size: 18px;
            line-height: 18px;
        }
        h2 {
            font-size: 40px;
            line-height: 40px;
        }
        p {
            font-size: 11px;
            line-height: 11px;
        }
    }
    .windons-quiz-result .windons-title-result .title-aswer {
        display: none;
    }
}

@media screen and (max-width: 450px){
    .section-icon-result-quiz .result-pending {
        padding: 10px;
        border-radius: 16px;
    }
    .windons-quiz-result {
        padding: 20px 20px 0 20px;
    }
}

.container-quiz-open{
    position: fixed;
    display: grid;
    min-width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    z-index: 9999;
    max-width: 100vh;
    align-items: center;
    justify-items: center;
    overflow-y: auto; 
    overflow-x: hidden;
    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
    &::-webkit-scrollbar-thumb {
        background-color: rgba(169, 169, 169, 0.562);
        border-radius: 10px;
    }
    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
    &::-webkit-scrollbar-track {
        @extend %glass;
        border-radius: 10px;
    }
    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
    &::-webkit-scrollbar {
        width: 8px;
    }
    .quiz-open-users{
        display: grid;
        width:80%;
        padding: 5%;
        color:$color6;
        text-align: center;
        grid-gap: 10px;
        max-width: 700px;
        background: $color8;
        border-radius: 50px;
        margin: 5% 0;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
        .brand{
            display: grid;
            justify-items: center;
            margin: 30px 0;
            img{
                width: 50%;
                max-width: 120px;
                height: auto;
            }
        }
        span{
            color: $color2;
        }
        .form-open-users{
            text-align: left;
            display: grid;
            width: 90%;
            grid-gap: 5px;
            padding: 0 5% 10% 5%;
            .input-date-open{
                display: grid;
                label{
                    padding: 0 15px;
                    font-size: 11px;
                    color:$color6;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 5%;
                    border-radius: 16px;
                    @extend %glass1;
                    width: 90%;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 5%;
                    border-radius: 16px;
                    @extend %glass1;
                    width: 90%;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-message{
                display: grid;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                    color: $color8;
                }
                textarea{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    resize: vertical; 
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .button-form-user-open{
                font-family: Poppins;
                font-size: 16px;
                padding: 8px;
                font-weight: 700;
                color: $color8;
                border: 2px solid $color2;
                border-radius: 16px;
                background: $color2;
                @extend %trans;
                cursor: pointer;
                margin-top: 15px;
                &:hover{
                    font-weight: 700;
                    color: $color2;
                    border: 2px solid $color2;
                    border-radius: 16px;
                    background: none;
                    @extend %trans;
                }
            }
        }
    }
}


