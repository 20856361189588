.main-politics{
    display: grid;
    min-height: 100vh;
    max-width: 100%;
    align-items: start;
    padding-top: 150px;
    .contect-politycs{
        display:  grid;
        max-width: 90%;
        padding: 5%;
        grid-gap: 30px;
        justify-items: center;
    }
    .title{
        text-align: center;
    }
    .subtitle3{
        text-align: center;
    }
    p{
        max-width: 90%;
    }
    img{
        width: 100%;
        max-width: 900px;
        height: auto;
    }
}

