@import "../../../../Constant.scss";

.section-log{
    display: grid;
    justify-items: center;
    z-index: 9999;
    .container-register{
        display: grid;
        width: 100%;
        color:$color8;
        text-align: center;
        grid-gap: 10px;
        margin: 60px 0;
        .brand{
            display: grid;
            justify-items: center;
            img{
                margin-top: 30px;
                width: 50%;
                max-width: 120px;
                height: auto;
            }
        }
        .subtitle1{
            color: $color6;
            span{
                color: $color2;
            }
        }
        .subtitle2{
            color: $color7;
            margin-bottom: 20px;
        }
        .form-contact{
            text-align: left;
            display: grid;
            grid-gap: 5px;
            padding: 0 5% 0 5%;
            input{
                background-color: white !important; /* Establece el fondo en blanco */
            }
            .input-date{
                display: grid;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    background: $color8;
                    @extend %glass1;
                    background-color: $color10;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        background: $color8;
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-clave{
                display: grid;
                grid-template-columns: auto 40px;
                margin-bottom: 5px;
                align-items: center;
                grid-template-areas: 
                    "label-t label-t"
                    "input-clave  button-password"
                    ;
                .label-t{
                    grid-area: label-t;
                    padding: 0 15px;
                    font-size: 12px;
                    color: $color6;
                }
                .input-clave{
                    grid-area: input-clave;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                .button-password{
                    grid-area:button-password;
                    margin-left: 5px;
                    border-radius: 16px;
                    padding: 5px 0;
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    border: none;
                    cursor: pointer;
                    svg{
                        font-size:20px;
                        color: $color2;
                    }
                }
            }
            .input-message{
                display: grid;
                label{
                    padding: 0 15px;
                    font-size: 12px;

                }
                textarea{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    resize: vertical; 
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .button-form{
                font-family: Poppins;
                font-size: 16px;
                padding: 8px;
                font-weight: 700;
                color: $color8;
                border: 2px solid $color2;
                border-radius: 16px;
                background: $color2;
                @extend %trans;
                cursor: pointer;
                &:hover{
                    font-weight: 700;
                    color: $color2;
                    border: 2px solid $color2;
                    border-radius: 16px;
                    background: none;
                    @extend %trans;
                }
            }
            .recapt{
                width: 100%;
                display: grid;
                justify-content: center;
                margin: 20px 0;
                .g-recaptcha {
                    width: 100%;
                    text-align: center;
                    border-radius: 20px;
                }
            }
        }
        .button-google{
            margin: 0 5% 0 5%;
            display: flex;
            font-family: Poppins;
            font-size: 14px;
            padding: 8px;
            color: $color7;
            border: 2px solid $color8;
            border-radius: 16px;
            background: $color8;
            @extend %trans;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            grid-gap: 15px;
            svg{
                font-size: 22px;
            }
            &:hover{
                font-weight: 700;
                border: 2px solid $color9;
                border-radius: 16px;
                background: $color9;
                @extend %trans;
            }
        }
        .password-reset{
            margin-bottom: 20px;
            color: $color7;
            @extend %trans;
            &:hover{
                color: $color2;
                @extend %trans;
            }
        }
    }
}