@import "../../../../Constant.scss";

.container-dates-dashboard{
    padding: 30px;
    margin: 20px;
    @extend %glass;
    border-radius: 30px;
    display: grid;
    grid-gap: 10px;
    justify-items: center;
    .dates-dashboard-kits{
        display: grid;
        justify-items: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        .grad{
            position: relative;
        }
        .result{
            font-size: 35px;
            position: absolute;
            color: $color1;
        }
    }
    .img-shofi-loanding{
        width: 80%;
        max-width: 200px;
    }
    .orden-numb{
        font-size: 40px;
        text-align: center;
        color: #575757;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
    .container-dates-delivery{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        grid-gap: 20px;
        .dates-delivery{
            display: grid;
            justify-content: center;
            justify-items: center;
            align-items: center;
            align-content: center;
            grid-gap: 10px;
            p{
                font-size: 14px;
                color: $color7;
                text-align: center;
                span{
                    font-size: 16px;
                    font-weight: 700;
                }
            }
        }
        svg{
            height: 20px;
            width: 20px;
            font-size: 20px;
            padding: 20px;
            color: $color8;
            border-radius: 50%;
        }
        .icon1{
            background: $color3;
        }
        .icon2{
            background: $color1;
        }
        .icon3{
            background: $color7;
        }
        .icon4{
            background: $color10;
        }
    }
}


@media screen and (max-width: 850px){
    .container-dates-dashboard{
        .container-dates-delivery{
            display: grid;
            grid-template-columns: repeat(2,1fr);
        }
    }
}