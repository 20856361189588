@import "../../../../Constant.scss";

.container-user{
    display: grid;
    .user-button{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-areas: "alert-user dates-user dates-user dates-user avatar-user";
        align-items: center;
        @extend %glass;
        border-radius: 25px;
        justify-items: center;
        justify-content: center;
        align-content: center;
        padding: 0.5vh;
        margin-right: 1vh;
        .alert-user{
            grid-area: alert-user;
            display: grid;
            border: none;
            background: none;
            font-family: $font;
            text-align: start;
            cursor: pointer;
            svg{
                font-size: 16px;
                padding: 8px;
                border-radius: 50%;
                background: $color1;
                color:$color8;
            }
            .numb-alert-user{
                position: fixed;
                display: grid;
                font-size: 10px;
                height: 20px;
                width: 20px;
                background: $color10;
                justify-content: center;
                align-items: center;
                color: $color11;
                border-radius: 50%;
                margin-left: -5px;
            }
        };
        .dates-user {
            grid-area: dates-user;
            border: none;
            background: none;
            font-family: $font;
            text-align: start;
            cursor: pointer;
            .name-user{
                display: flex;
                font-size: 12px;
                font-weight: 700;
            }
            .email-name{
                font-size: 10px;
            }
        };
        .avatar-user {
            grid-area: avatar-user;
            border: none;
            background: none;
            font-family: $font;
            text-align: start;
            cursor: pointer;
            .avatar-letter{
                font-size: 25px;
                font-weight: 300;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                line-height: 0px;
                background: $color5;
                color: $color8;
                padding: 10px;
                height: 25px;
                width: 25px;
                border-radius: 50%;
                display: grid;
                justify-content: center;
                align-items: center;
                justify-items: center;
                align-content: center;
            }
            .avatar-img {
                width: 5vh;
                height: 5vh;
                min-width: 35px;
                min-height: 35px;
                border-radius: 50%; /* Esto crea un círculo */
                overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                position: relative; /* Permite centrar vertical y horizontalmente */
                .avatar-img img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                    object-position: center center; /* Centra la imagen */
                }
            }
        };
    }
    .wimdons-user{
        width: 250px;
        display: grid;
        background: #e4ecf7;
        position: fixed;
        margin-top: 80px;
        border-radius: 25px;
        right: 0;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.308);
        .nav-wimdons-user{
            display: grid;
            padding: 10px;
            grid-gap: 5px;  
            .button-nav-user2{
                display: flex;
                grid-gap: 10px;
                font-family: $font;
                font-size: $fsize;
                text-decoration: none;
                color:$color1;
                font-weight: 500;
                @extend %trans;
                background:none;
                padding: 15px 20px;
                border-radius: 15px;
                font-weight: 500;
                cursor: pointer;
                align-items: center;
                p{
                    display: flex;
                    color: $color6;
                }
                .logout{
                    display: flex;
                    align-items: center;
                    grid-gap: 10px;
                    svg{
                        font-size: 20px;
                    }
                }
                &:hover{
                    color: $color2;
                    @extend %trans;
                    background: $color11;
                    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.062);
                }
            }       
        }
        .button-nav-user{
            display: flex;
            grid-gap: 10px;
            font-family: $font;
            font-size: $fsize;
            text-decoration: none;
            color:$color7;
            font-weight: 500;
            @extend %trans;
            background:none;
            margin-right: 10px;
            padding: 15px 20px;
            border-radius: 15px;
            font-weight: 500;
            cursor: pointer;
            align-items: center;
            .dates-alert{
                display: grid;
                .assing{
                    font-size: 12px;
                }
                .date{
                    font-size: 9px;
                }
                .action{
                    font-size: 11px;
                }
            }
            .logout{
                display: flex;
                align-items: center;
                grid-gap: 10px;
                svg{
                    font-size: 20px;
                }
            }
            &:hover{
                color: $color2;
                @extend %trans;
                background: $color11;
                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.062);
            }
        }   
        .nav-wimdons-alert{
            display: grid;
            padding: 20px 10px 20px 20px;
            .wimdons-alert{
                display: grid;
                grid-gap: 5px;
                max-height: 20vh;
                overflow-y: auto;
                grid-gap: 10px;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
            }
        }
    }
}



@media screen and (max-width: 750px){
    .container-user{
        .user-button{
            grid-template-columns: 55% 45%;
            grid-template-areas: "alert-user avatar-user";
            // height: 48px;
            .dates-user {
                display: none;
                .name-user{
                    display:none;
                }
                .email-name{
                    display: none;
                }
            };
        }
    }    
}