@import "../../../../Constant.scss";

.starco-admin{
    padding: 40px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    height: 90%;
    min-height: 70vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .encabezado-users-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .encabezado-users-button{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            align-items: center;
        }
    }
    .form-contact-e{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 30% auto;
        grid-gap: 5%;
        align-items: start;
        .column-img{
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr;
            .custom-file-upload{
                color: $color11;
                background: #1E67E2;
                border: #1E67E2 solid 2px;
                padding: 10px 15px;
                text-decoration: none;
                border-radius: 16px;
                display: flex;
                align-content: center;
                align-items: center;
                grid-gap: 10px;
                font-size: 14px;
                @extend %trans;
                justify-content: center;
                font-family: $font;
                margin-bottom: 30px;
                cursor: pointer;
                svg{
                    font-size: 22px;
                }
                &:hover{
                    color: #1E67E2;
                    background: none;
                    @extend %trans;
                    svg{
                        font-size: 24px;
                        color: #1E67E2;
                    }
                }
            }
        }
        .column-form{
            display: grid;
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                option{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                textarea{
                    max-width: 100%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    resize: vertical; 
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .column-form-date{
                display: grid;
                grid-template-columns: 180px auto;
            }
            .input-date-hour{
                display: grid;
                width: 100%;
                grid-gap: 10px;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                .hour-selection{
                    display: flex;
                    grid-gap: 10px;
                    align-items: center;
                }
            }
            .input-date-selection{
                display: grid;
                grid-gap: 10px;
                .date-selection-button{
                    background: $color9;
                    color: $color7;
                    border: 2px solid #5c5c5c;
                    padding: 10px 20px;
                    border-radius: 16px;
                }
                .date-selection-button-select{
                    background: $color11;
                    color: $color10;
                    border: 3px solid #ff4444;;
                    padding: 10px 20px;
                    border-radius: 16px;
                }
            }
        }
        .section-img{
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            align-content: center;
            grid-gap: 30px;
            .input-img{
                width:  max(10vw, 100px);
                height: max(10vw, 100px);
                border-radius: 50%; /* Esto crea un círculo */
                overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                position: relative; /* Permite centrar vertical y horizontalmente */
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                    object-position: center center; /* Centra la imagen */
                }
            }
        }
        .input-message{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color8;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-button{
            display: flex;
            margin-top: 15px;
        }
    }
    .scroll-equip-admin{
        max-height: 64vh;
        padding: 20px 30px 10px 30px;
        align-items: center;
        justify-items: center;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
}

.file-reserve-startco{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto;
    grid-gap: 30px;
    padding: 50px;
    border-radius: 50px;
    @extend %glass1;
    margin-bottom: 30px;
    span{
        font-size: 14px;
        font-weight: 600;
        color: $color6;
    }
    p{
        font-size: 18px;
        color: #5c5c5c;
    }
    a{
        text-decoration: none;
    }
    .file-reserve{
        display: grid;
        grid-gap: 8px;
    }
}

.file-reserve-notification{
    display: flex;
    grid-gap: 30px;
    padding: 50px;
    border-radius: 50px;
    @extend %glass1;
    margin-bottom: 50px;
    justify-content: space-between;
    align-items: flex-start;
    .button-reserve{
        display: flex;
        grid-gap: 30px;
    }
    .input-startco{
        display: flex;
        .input-date{
            display: grid;
            width: 90%;
            padding: 0 5% 0 0;
            label{
                padding: 0 15px;
                font-size: 11px;
                color:$color7;
            }
            select{
                width: 95%;
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .date-selection-button{
            padding: 8px 15px;
            color: #ff4444;
            border: #ff4444 2px solid;
            border-radius: 16px;
        }
        .date-selection-button-send{
            padding: 8px 15px;
            color: #808080;
            border: #808080 2px solid;
            border-radius: 16px;
        }
    }
}