@import "../../../../Constant.scss";

.respuestas-encuesta{
    background: $color8;
    color: $color7;
    font-weight: 800;
    h2{
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
}