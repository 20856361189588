@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import "../../../../Constant.scss";
.section-1-contect{
    width: 100%;
    display: grid;
    justify-items: center;
    max-width: 2400px;
    .section-1{
        width: 96vw;
        .img-carrusel{
            width: 20vw;
            height: 150px;
            display: grid !important;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;        
        }
        .img-section-1{ 
            width: 200px;
            height: auto;
            opacity: 0.6;
            @extend %trans;
            &:hover{
                opacity: 1;
                @extend %trans;
            }
        }
    }
}


@media screen and (max-width: 1700px){
    .section-1-contect{
        .section-1{
            width: 92vw;
        }
    }
}

@media screen and (max-width: 800px){
    .section-1-contect{
        .section-1{
            width: 85vw;
            .img-section-1{ 
                width: 200px;
            }
        }
    }
}

@media screen and (max-width: 550px){
    .section-1-contect{
        .section-1{
            width: 80vw;
            .img-section-1{ 
                width: 150px;
            }
        }
    }
}