@import "../../../../Constant.scss";

.list-users{
    display: grid;
    align-content: space-between;
    height: 100%;
    .input-search {
        font-size: 16px;
        color:$color7;
        @extend %glass1;
        padding: 14px 18px;
        border-radius: 18px;
        border: none;
        font-family: "Poppins", sans-serif;
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    .pagination-numb{
        display: grid;
        justify-items: center;
    }
}


@media screen and (max-width: 950px){
    .list-users{
        .input-search {
            font-size: 12px;
            padding: 14px 15px;
            border-radius: 16px;
        }
    }    
}
