@import "../../../../Constant.scss";


.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-4Pl-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns:45% auto;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-4Pl-title  section-4Pl-img";
        max-width: 1400px;
        grid-gap: 5%;
        width: 60%;
        min-height: 20vw;
        .section-4Pl-img {
            grid-area: section-4Pl-img ;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 60vh;
            border-radius: 50px;
            .img-4Pl {
                width: 100%;
                height: auto; 
                border-radius: 50px; 
                margin-bottom: -150px;
            }
        }
        .section-4Pl-title{
            grid-area: section-4Pl-title;
            display: grid;
            align-items: start;
            align-content: center;
            grid-gap: 20px;
            z-index: 99;
            text-align: start;
            grid-template-columns: 30px auto;
            .section-4Pl-icon{
                width: 40px;
            }
            .title-page-4Pl{
                font-size: 30px;
                line-height: 40px;
                text-align: start;
                font-weight: 400;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
                color: $fontcolor;
                margin-bottom: 20px;
                span{
                    font-style: oblique;
                    font-weight: 700;
                }
            }
            .subtitle-page-4Pl{
                font-size: 16px;
                line-height: 25px;
                text-align: start;
                font-weight: 400;
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin: 0;
                padding: 0;
                color: $fontcolor;
                span{
                    font-weight: 600;
                }
            }
        }
    }
}

@media screen and (max-width: 1550px){
    .section-contect{
        .section-4Pl-contect-container{
            width: 80%;
        }
    }
}

@media screen and (max-width: 1050px){
    .section-contect{
        .section-4Pl-contect-container{
            margin: 20px 0;
            min-height: 35vh;
            grid-gap: 30px;
            .section-4Pl-title{
                grid-gap: 30px;
                .section-4Pl-icon{
                    width: 30px;
                }
                .title-page-4Pl{
                    font-size: 25px;
                    line-height: 27px;
                }
                .subtitle-page-4Pl{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .section-4Pl-img{
                .img-4Pl {
                    border-radius: 20px;
                    margin-bottom: 0px;
                }
            } 
        }
    }
}

@media screen and (max-width: 950px){
    .section-contect{
        .section-4Pl-contect-container{
            width: 90%;
        }
    }
}



@media screen and (max-width: 750px){
    .section-contect{
        .section-4Pl-contect-container{
            margin: 20px 0;
            min-height: 35vh;
            grid-template-columns: 1fr;
            grid-template-areas: "section-4Pl-img " "section-4Pl-title ";
            .section-4Pl-title{
                grid-gap: 0px;
                .section-4Pl-icon{
                    width: 25px;
                }
                .title-page-4Pl{
                    font-size: 20px;
                    line-height: 22px;
                }
                .subtitle-page-4Pl{
                    font-size: 15px;
                    line-height: 16px;
                }
            }
            .section-4Pl-img .img-4Pl {
                width: 90%;
                height: auto;
                max-width: 400px;
            }
        }
    }
}