@import "../../../../Constant.scss";

.section-0 {
    display: grid;
    padding: 50px 0 200px 0;
    justify-items: center;
    color: $color7;
    .section-0-container {
        display: grid;
        grid-gap: 5%;
        justify-items: center;
        max-width: 1400px;
        .title-page-catalogo{
            font-size: 35px;
            line-height: 37px;
            font-weight: 400;
            margin-block-start: 0em;
            margin-block-end: 0em;
            padding: 10px 0;
            color: $fontcolor;
            text-align: center;
            span{
                font-weight: 600;
                font-style: oblique;
            }
        }
        .sectionk-0-contect {
            display: flex;
            max-width: 1400px;
            width: 85vw;
            grid-gap: 20px;
        }
    }
}

.list-link {
    display: flex;
    width: 25%;
    text-decoration: none !important;
    height: 500px;
    flex-direction: column;
    align-items: flex-start; // Cambio aquí
    justify-content: flex-end;
    overflow: hidden;
    @extend %trans;
    img {
        object-fit: cover;
        object-position: center;
        border-radius: 40px;
    }
    &:hover{
        text-decoration: none !important;
    }
}

.list-link-grand {
    width: 40vw;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start; // Cambio aquí
    justify-content: flex-end;
    overflow: hidden;
    @extend %trans;
    img {
        object-fit: cover;
        object-position: center;
        border-radius: 40px;
    }
}

.list-title {
    margin: 20px;
    padding: 10px 15px;
    border-radius: 16px;
    background: $color1;
    color: $color11;
    font-weight: 700;
}


@media screen and (max-width: 1250px){
    .section-0{
        .section-0-container{
            .sectionk-0-contect{
                width: 95vw;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .section-0 {
        display: grid;
        padding: 40px 0 100px 0;
        .section-0-container {
            .title-page-catalogo{
                font-size: 22px;
                line-height: 25px;
            }
        }
    }
    .list-link {
        display: flex;
        width: 25%;
        height: 300px;
        img {
            border-radius: 20px;
        }
    }
    .list-link-grand{
        width: 40vw;
        height: 300px;
        display: flex;
        img {
            border-radius: 20px;
        }
    }
    .list-title{
        margin: 5px;
        padding: 5px;
        font-size: 12px;
        writing-mode: vertical-rl;
        transform: rotate(180deg); 
    }
}


@media screen and (max-width: 550px){
    .list-link {
        display: flex;
        width: 25%;
        height: 200px;
        img {
            border-radius: 20px;
        }
    }
    .list-link-grand{
        width: 60vw;
        height: 200px;
        display: flex;
        img {
            border-radius: 20px;
        }
    }
    .list-title{
        margin: 5px;
        padding: 5px;
        font-size: 12px;
        writing-mode: vertical-rl;
        transform: rotate(180deg); 
    }
}