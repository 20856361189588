@import "../../../../Constant.scss";


.siigo-true{
    color: #4EA6D6;
}

.siigo-false{
    color: #474747;
}




.list-balance{
    display: grid;
    align-content: space-between;
    height: 100%;
    .title-balance-list{
        display: grid;
        grid-template-columns: auto 80px;
        p{
            color: #474747;
        }
    }
    .title-balance{
        color: #0470c2; 
        font-size: 20px; 
        font-weight: 600;
        
    }
    .contect-encabezado-list-balance{
        display: flex;
    }
    .date-contect-balance{
        display: flex;
        justify-content: center;
        grid-gap: 5%;
        width: 400px;
        .input-date-orden{
            display: grid;
            width: 100%;
            label{
                padding: 0 10px;
                font-size: 11px;
                color:$color7;
            }
            input{
                width: 96%;
                font-family: Poppins;
                font-size: 12px;
                padding: 5px 2%;
                border-radius: 12px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            select{
                width: 96%;
                font-family: Poppins;
                font-size: 12px;
                padding: 5px 2%;
                border-radius: 12px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }        
    }
    .contect-list-balance{
        display: grid;
        grid-gap: 1vh;
        grid-template-rows: 5vh 71vh 4vh;
        padding: 2vh 2vh 0vh 2vh;
        .list-balance{
            display: flex;
            flex-direction: column;
            height: 67vh;
            grid-gap: 5px;
            padding: 2vh;
            overflow-y: auto; 
            overflow-x: hidden;
            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
            &::-webkit-scrollbar-thumb {
                background-color: rgba(169, 169, 169, 0.562);
                border-radius: 10px;
            }
            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
            &::-webkit-scrollbar-track {
                @extend %glass;
                border-radius: 10px;
            }
            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
            &::-webkit-scrollbar {
                width: 8px;
            }
            .item-movi{
                display: grid;
                width: 100%;
                justify-content: space-between;
                grid-template-columns: auto 80px;
                text-decoration: none;
                border-radius: 20px;
                @extend %trans;
                @extend %glass;
                align-items: center;
                .info-balance{
                    font-size: 20px;
                    margin-block-start: 0em;
                    margin-block-end: 0em;
                    padding: 10px 0;
                }
                .dates-info-balance{
                    padding: 10px 10px 10px 30px;
                }
            }
        }
        .encabezado-list-balance{
            display: flex;
            align-content: flex-start;
            align-items: center;
            justify-content: space-between;
            .input-search{
                font-size: 14px;
                color: $color6;
                padding:12px 15px;
                border-radius: 15px;
                border: none;
                @extend %glass;
                font-family: $font;
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
    }
    .pagination-numb{
        display: grid;
        justify-items: center;
        background: white;
    }
}

@media screen and (max-width: 1250px){
    .list-users{
        .contect-list-users{
            grid-gap: 1vh;
            grid-template-rows: 5vh 70vh 5vh;
            .list{
                display: flex;
                flex-direction: column;
                height: 65vh;
                padding: 2vh;
            }
        }
    }
}

@media screen and (max-width: 1150px){
    .button-new2{
        display: none !important;
    }
}

@media screen and (max-width: 980px){
    .list-balance .date-contect-balance {
        display: none;
    }
}


@media screen and (max-width: 680px){
    .list-users{
        .title-equip-list{
            display: none;
        }
    }
}

@media screen and (max-width: 500px){
    .buttom-orden-container{
        display: none;
    }
}