@import "../../../../Constant.scss";

.carrucel-kits{
    display: grid;
    justify-items: center;
    grid-gap: 30px;
    .carrucel-item-kits{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        height: 100%;
        width: 90vw;
        justify-items: center;
        justify-content: center;
        .carrucel-item-kits-2-slider{
            width: 100%;
            max-width: 60vw;
        }
    }
    .title-page-1p{
        font-size: 40px;
        line-height: 52px;
        font-weight: 400;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding: 0;
        color: $fontcolor;
        text-align: center;
        span{
            color: $color2;
            font-weight: 600;
            font-style: oblique;
        }
    }
    .subtitle-page-1p{
        font-size: 20px;
        line-height: 32px;
        color: $fontcolor;
        width: 80vw;
        max-width: 800px;
        text-align: center;
    }
    .slick-next:before{
        color: $color7 !important;
        margin-left: -30px !important;
    }
    .slick-prev:before{
        color: $color7 !important;
        margin-right: -30px !important;
    }
}

@media screen and (max-width: 1250px){
    .carrucel-kits{
        padding: 50px 0px;
        .carrucel-item-kits{
            .carrucel-item-kits-2-slider{
                width: 100%;
                max-width: 90vw;
            }
        }
    }
}


@media screen and (max-width: 650px){
    .carrucel-kits{
        .title-page-1p{
            font-size: 25px;
            line-height: 26px;
        }
        .subtitle-page-1p{
            font-size: 12px;
            line-height: 14px;
        }
        .carrucel-item-kits{
            .carrucel-item-kits-2-slider{
                max-width: 80vw;
            }
        }
    }
}

@media screen and (max-width: 650px){
    .carrucel-kits{
        .carrucel-item-kits{
            .carrucel-item-kits-2-slider{
                max-width: 60vw;
            }
        }
    }
}
