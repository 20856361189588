@import "../../../../Constant.scss";

.empty-form{
    position: relative;
    display: grid;
    width: 80%;
    height: 55%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    padding: 10%;
    .img-shofi-loanding{
        width: 80%;
        max-width: 200px;
    }
    .title-shofi-loanding{
        font-size: 2.2vw;
        line-height: 2.2vw;
        text-align: center;
        color: $color7;
    }
}

@media screen and (max-width: 950px){
    .empty-form{
        .title-shofi-loanding{
            font-size: 16px;
            line-height: 17px;
        }
    }
}


@media screen and (max-width: 450px){
    .empty-form{
        .title-shofi-loanding{
            font-size: 14px;
            line-height: 15px;
        }
    }
}
