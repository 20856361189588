@import "../../../../Constant.scss";

.movil{
    height: 100vh;
    width: 100%;
    @extend %glass;
    position: fixed;
    display: grid;
    align-items: center;
    justify-items: center;
    .container-movil{
        padding: 0px 50px 50px 50px;
        display: flex;
        flex-direction: column;
        .movil-menu{
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-gap: 20px;
            .button-navbar{
                font-family: $font;
                font-size: $fsize;
                text-decoration: none;
                color:$fontcolor;
                font-weight: 500;
                @extend %trans;
                &:hover{
                    font-size: 18px;
                    color: $color2;
                    @extend %trans;
                }
            }
            .button-color{
                cursor: pointer;
                display: flex;
                font-family: $font;
                font-size: $fsize;
                text-decoration: none;
                color:$color8;
                background: $color2;
                padding: 10px 20px;
                border-radius: 20px;
                border: 2px solid $color2;
                font-weight: 500;
                @extend %trans;
                grid-gap: 10px;
                align-content: center;
                align-items: center;
                &:hover{
                    color: $color2;
                    @extend %trans;
                    background: none;
                    border: 2px solid $color2;
                }
            }
        }
        .redes-menu{
            justify-content: space-between;
            margin-top: 40px;
            display: flex;
            grid-gap: 10px;
            .redes{
                background: $color2;
                padding: 10px;
                border-radius: 50%;
                display: grid;
                cursor: pointer;
                border: $color2 2px solid;
                svg{
                    color: $color8;
                    font-size: 12px;
                    height: 20px;
                    width: 20px;
                    margin: 0;
                }
                &:hover{
                    background: none;
                    svg{
                        color: $color2;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 800px){
    .movil{
        display: none;
        cursor: pointer;
    }
}