@import "../../../../Constant.scss";

.status-orden{
    display: grid;
    justify-items: end;
    align-items: start;
}

.unassigned-o{
    font-size: 16px;
    padding: 10px;
    background: $color7;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.verification-o{
    font-size: 16px;
    padding: 10px;
    background: $color4;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.packing-o{
    font-size: 16px;
    padding: 10px;
    background: $color3;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.preparation-o{
    font-size: 16px;
    padding: 10px;
    background: $color3;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}


.pickup-o{
    font-size: 16px;
    padding: 10px;
    background: $color2;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.route-o{
    font-size: 16px;
    padding: 10px;
    background: $color1;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.delivered-o{
    font-size: 16px;
    padding: 10px;
    background: $color13;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.cancelled-o{
    font-size: 16px;
    padding: 10px;
    background: $color10;
    color: $color8;
    border-radius: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    grid-gap: 10px;
}

.loanding-o{
    font-size: 16px;
    color: $color7;
    border-radius: 16px;
}