@import "../../../../Constant.scss";

.section-contect{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-0P-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: auto 50%;
        min-height: 45vh;
        position: relative;
        grid-template-areas: "section-0P-img  section-0P-title ";
        margin: 130px 0px 50px 0px; 
        max-width: 1400px;
        grid-gap: 50px;
        width: 90%;
    }
    .section-0P-title{
        grid-area: section-0P-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 50px;
        z-index: 99;
        text-align: start;

        .title-page-0p{
            font-size: 50px;
            line-height: 65px;
            text-align: start;
            font-weight: 500;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $fontcolor;
            span{
                font-style: oblique;
                color: $color3;
            }
        }
        .subtitle-page-0p{
            font-size: 20px;
            line-height: 32px;
            text-align: start;
            font-weight: 400;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 0;
            padding: 0;
            color: $fontcolor;
            span{
                font-weight: 600;
            }
        }
        .container-button-nav-0p{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
    }
    .section-0P-img {
        grid-area: section-0P-img ;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 60vh;
        .img-0P {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
}

@media screen and (max-width: 780px){
    .section-contect{
        .section-0P-contect-container{
            grid-template-columns: 1fr;
            grid-template-areas: "section-0P-img"  "section-0P-title";
        }
        .section-0P-img .img-0P {
            border-radius: 30px;
        }
    }
}

@media screen and (max-width: 510px){
    .section-contect{
        .section-0P-contect-container {
            grid-gap: 20px;
        }
        .section-0P-title{
            grid-gap: 25px;
            .title-page-0p{
                font-size: 28px;
                line-height: 40px;
            }
            .subtitle-page-0p{
                font-size: 15px;
                line-height: 18px;
            }
        }
    }
}